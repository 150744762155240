import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private router = inject(Router);


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!request.url.includes('/v1/tNh5WSLo/users')) {
          if (error) {
            switch (error.status) {
              case 400:
                console.error('Bad Request:', error.error);
                break;

              case 401:
                console.error('Unauthorized - Redirecting to login.');
                this.router.navigate(['/login']);
                break;

              case 403:
                console.error('Forbidden - Access Denied.');
                break;

              case 404:
                console.error('Not Found:', error.error);
                break;

              case 500:
                console.error('Internal Server Error:', error.error);
                break;

              default:
                console.error('Unhandled Error:', error);
                break;
            }
          }
        }

        return throwError(
          () => new Error(error.message || 'Unknown error occurred.')
        );
      })
    );
  }
}
