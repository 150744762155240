<nav class="layout-breadcrumb">
  <ol>
    @for (item of breadcrumbs$ | async; track $index; let last = $last) {
      <li>{{ item.label }}</li>
      @if (!last) {
        <li class="layout-breadcrumb-chevron">/</li>
      }
    }
  </ol>
</nav>
