import { Component, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonDirective } from 'primeng/button';
import { Ripple } from 'primeng/ripple';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html',
    standalone: true,
    imports: [SidebarModule, ButtonDirective, Ripple]
})
export class AppProfileSidebarComponent {
    layoutService = inject(LayoutService);


    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }
}
