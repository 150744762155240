import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@shared/environments';
@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private http = inject(HttpClient);

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}appointments`;
  // private reportUrl = environment.reportUrl;

  getAppointments(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/`, this.httpOptions);
  }
  getAppointmentByAppointmentId(appointmentId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${appointmentId}`,
      this.httpOptions
    );
  }
  updateAppointmentByAppointmentId(
    appointmentId: number,
    appointmentData: { appointmentDescr: string; appointmentDate: Date }
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/${appointmentId}`,
      appointmentData,
      this.httpOptions
    );
  }
  getUserAppointmentsByUserId(): Observable<any> {
    return this.http.get<any>(
      // `${this.apiUrl}/${userId}/patientAppointments`,
      `${this.apiUrl}/`,
      this.httpOptions
    );
  }
  getAppointmentStatuses(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/appointmentStatuses`,
      this.httpOptions
    );
  }
  getCompletedAppointments(): Observable<any> {
    /* TODO */
    return this.http.get<any>(
      `${this.apiUrl}/completedAppointments`,
      this.httpOptions
    );
  }

  createAppointment(appointment: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, appointment);
  }

  // updateAppointmentStatusById(
  //   appointmentId: number,
  //   status: any
  // ): Observable<any> {
  //   return this.http.put<any>(
  //     `${this.apiUrl}/${appointmentId}/status`,
  //     status,
  //     this.httpOptions
  //   );
  // }
  updateAppointmentDateById(
    appointmentId: number,
    appointmentDate: { date: string; time: string }
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/${appointmentId}/date`,
      appointmentDate,
      this.httpOptions
    );
  }
}
