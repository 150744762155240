import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AggregatorService extends BaseService {
  constructor() {
    super();
  }
  getClinicMembersByClinicId(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/clinicMembers?userId=${userId}`,
      this.httpOptions
    );
  }
  getClinicReferralsByClinicIdAndPatientSocialSecurityNumber(
    clinicId: string,
    ssn: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/clinicReferrals?userId=${clinicId}&ssn=${ssn}`,
      this.httpOptions
    );
  }
  getDoctorMembersByDoctorId(doctorId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/doctorMembers?userId=${doctorId}`,
      this.httpOptions
    );
  }
  getInsuranceMembersById(insuranceId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/insuranceMembers?userId=${insuranceId}`,
      this.httpOptions
    );
  }
  getInsuredLastestActivities(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/latestActivities?userId=${userId}`,
      this.httpOptions
    );
  }
  getMemberAppointments(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/memberAppointments?userId=${userId}`,
      this.httpOptions
    );
  }

  getMemberDiagnoses(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/memberDiagnoses?userId=${userId}`,
      this.httpOptions
    );
  }
  getMemberReferrals(userId: string, clinicUserId?: string) {
    if (clinicUserId) {
      return this.http.get<any>(
        `${this.apiUrl}aggregator/memberReferrals?userId=${userId}?clinicId=${clinicUserId}`,
        this.httpOptions
      );
    } else {
      return this.http.get<any>(
        `${this.apiUrl}aggregator/memberReferrals?userId=${userId}?`,
        this.httpOptions
      );
    }
  }
  getUserAppointments(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}aggregator/userAppointments?userId=${userId}`,
      this.httpOptions
    );
  }
  postProvideAccessToMedicalRecord(): Observable<any> {
    // throw 'Unimplemented';
    return this.http.post<any>(
      `${this.apiUrl}aggregator/provideMedicalRecordAccess`,
      this.httpOptions
    );
  }
  updateAppointmentStatus(
    appointmentId: number,
    status: string
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}aggregator/appointmentStatus?appointmentId=${appointmentId}&status=${status}`,
      this.httpOptions
    );
  }
}
