import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  Component,
  computed,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
} from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'table-plain',
  standalone: true,
  imports: [TableModule, DatePipe, TagModule, PaginatorModule, CurrencyPipe],
  templateUrl: './table-plain.component.html',
  styleUrl: './table-plain.component.css',
})
export class TablePlainComponent {
  data: InputSignal<any[]> = input.required();
  dataLoading: InputSignal<boolean> = input.required();
  tableConfig: InputSignal<TableConfigI> = input.required();

  showModal: OutputEmitterRef<boolean> = output();
  private _rowsPerPage = signal(5);
  private _first = signal(0);

  paginatedData = computed(() =>
    this.data().slice(this._first(), this._first() + this._rowsPerPage())
  );

  getSeverity(status: any) {
    switch (status) {
      case 'Επιβεβαιωμένο':
        return 'info';
      case 'Απορρίφθηκε':
        return 'danger';
      case 'Εκκρεμεί':
        return 'warning';
      case 'Ακυρωμένο':
        return 'danger';
      case 'Ολοκληρωμένο':
        return 'success';

      case 1:
        return 'info';
      case 2:
        return 'danger';
      case 3:
        return 'secondary';
      case 4:
        return 'danger';
      case 5:
        return 'success';
      default:
        return 'danger';
    }
  }

  onPageChange(event: any) {
    this._first.set(event.first);
    this._rowsPerPage.set(event.rows);
  }
  openModal() {
    this.showModal.emit(true);
  }
}

export interface TableConfigI {
  headers: string[];
  jsonKeys: string[];
  dataType: Array<'TEXT' | 'DATE' | 'TAG' | 'CURRENCY' | 'DETAILS'>;
}
