import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private http = inject(HttpClient);

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  _appName: string = '';

  private apiUrl = `${environment.api}members/`;
  private api = `${environment.api}`;

  get appName() {
    return this._appName;
  }
  set appName(appname: string) {
    this._appName = appname;
  }
  getClientHistory(): Observable<any> {
    return of([
      {
        userId: 60,
        examDate: '2023-09-12',
        type: 'νοσηλεία',
        cost: 300,
        notes: 'Εισαγωγή λόγω τραυματισμού',
        status: 'ολοκληρώθηκε',
      },
      {
        userId: 60,
        examDate: '2023-10-25',
        type: 'εξετάσεις',
        cost: 120,
        notes: 'Αιματολογικές εξετάσεις',
        status: 'εκκρεμεί',
      },
      {
        userId: 60,
        examDate: '2024-01-10',
        type: 'νοσηλεία',
        cost: 800,
        notes: 'Χειρουργική επέμβαση',
        status: 'ολοκληρώθηκε',
      },
      {
        userId: 60,
        examDate: '2024-02-18',
        type: 'εξετάσεις',
        cost: 50,
        notes: 'Τακτικός έλεγχος',
        status: 'εκκρεμεί',
      },
      {
        userId: 60,
        examDate: '2023-11-05',
        type: 'νοσηλεία',
        cost: 1500,
        notes: 'Μακροχρόνια νοσηλεία',
        status: 'ολοκληρώθηκε',
      },
    ]);
  }
  // Auditor App
  getAllInsured(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}`, this.httpOptions);
  }
  getMemberInfoByMemberId(memberId: string) {
    return this.http.get<any>(
      `${this.api}members/${memberId}`,
      this.httpOptions
    );
  }

  getAllClients(insuranceId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}insurance/${insuranceId}/members`,
      this.httpOptions
    );
  }

  getClientDetails(insuredId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}members/${insuredId}`,
      this.httpOptions
    );
  }

  getEntityByUsername(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    if (this.appName == 'insured') {
      return this.http.get<any>(`${this.api}members/me`, {
        headers,
      });
    } else if (this.appName == 'insurers') {
      return this.http.get<any>(`${this.api}insurances/me`, {
        // headers,
      });
    } else if (this.appName == 'doctor') {
      return this.http.get<any>(`${this.api}doctors/me`, {
        // headers,
      });
    } else if (this.appName == 'medicalCenter') {
      return this.http.get<any>(`${this.api}medicalCenters/me`, {
        // headers,
      });
    } else {
      return of(null);
    }
  }

  getInsuredClinicalExamsByMemberId(memberId: string) {
    return this.http.get<any>(
      `${this.api}referrals/${memberId}/clinicalExams`,
      this.httpOptions
    );
  }
  getMemberByUsername(): Observable<any> {
    // // console.log(this.auth_token);
    return this.http.get<any>(`${this.apiUrl}me`);
  }
  getMemberDetailsById(userId: string): Observable<any> {
    // console.log(this.auth_token);
    return this.http.get<any>(`${this.apiUrl}${userId}`);
  }

  getInsurersByUserId(userId: string): Observable<any> {
    /* TODO */
    return this.http.get<any>(`${this.apiUrl}/${userId}/insurers`);
  }
  getContracts(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}policies`,
      this.httpOptions
    );
  }
  getCompensations(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}members/${userId}/compensations`,
      this.httpOptions
    );
  }
  getClaimsInsideNetwork(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}members/${userId}/claims-inside-network`,
      this.httpOptions
    );
  }
  getClaimsOutsideNetwork(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}members/${userId}/claims-outside-network`,
      this.httpOptions
    );
  }

  getHistoryOfDiagnosis(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}members/${userId}/history-of-diagnosis`,
      this.httpOptions
    );
  }
}
