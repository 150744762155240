import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralService extends BaseService {
  constructor() {
    super();
  }

  getExamTypes() {
    return this.http.get<any>(
      `${this.apiUrl}referrals/exams`,
      this.httpOptions
    );
  }
  createDiagnosis(diagnosis: any) {
    return this.http.post<any>(
      `${this.apiUrl}referrals/diagnosis`,
      diagnosis,
      this.httpOptions
    );
  }
  getDiagnosisByUserId(userId: number) {
    return this.http.get<any>(
      `${this.apiUrl}referrals/${userId}/diagnoses`,
      this.httpOptions
    );
  }
  selfAssignExamToClinic(referralId: number) {
    return this.http.put<any>(
      `${this.apiUrl}referrals/${referralId}/selfAssignExam`,
      this.httpOptions
    );
  }
  getExamReferencesByUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs`,
      this.httpOptions
    );
  }

  getICD10List() {
    return this.http.get<any>(
      `${this.apiUrl}referrals/icd10s`,
      this.httpOptions
    );
  }
  getKenList() {
    return this.http.get<any>(`${this.apiUrl}referrals/kens`, this.httpOptions);
  }
  getICD10ByNameOrCode(
    ICD10Name: string | undefined,
    code: string | undefined
  ) {
    if (ICD10Name) {
      return this.http.get<any>(
        `${this.apiUrl}referrals/icd10s?name=${ICD10Name}`,
        this.httpOptions
      );
    } else {
      return this.http.get<any>(
        `${this.apiUrl}referrals/icd10s?code=${code}`,
        this.httpOptions
      );
    }
  }

  getExamRefByUserAndClinicId(
    userId: string,
    clinicId: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examRefs/${clinicId}`,
      this.httpOptions
    );
  }
  // getExamRefByPatientSocialSecuritNumberAndClinicId(
  //   patientSocialSecurityNumber: string,
  //   clinicUserId: number
  // ): Observable<any> {
  //   return this.http.get<any>(
  //     `${this.apiUrl}exams/examReferences/${clinicUserId}/${patientSocialSecurityNumber}`,
  //     this.httpOptions
  //   );
  // }
  addExamResult(examObject: any) {
    return this.http.post<any>(
      `${this.apiUrl}exams/results`,
      examObject,
      this.httpOptions
    );
  }
  // returns list of exams in insured-app
  getPatientExamResults(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}exams/${userId}/examResults`,
      this.httpOptions
    );
  }

  createExams(exams: any) {
    return this.http.post<any>(`${this.apiUrl}exams/new`, exams);
  }
}
