import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@shared/environments';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InsuranceClaimsService {
  private http = inject(HttpClient);

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = `${environment.api}insurers/`;

  getClaims(): Observable<any> {
    return of([
      {
        caseId: 1,
        fraudDataId: 1,
        caseManager: 'Giorgos Papadopoulos',
        investigator: 'Yannis Vasilakis',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.89,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 2618.699920654297,
        accidentProfile: '1',
        hcaReport: 'reports/326338343_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.427',
        created: null,
        updated: '2024-07-13T07:12:35.41',
        costEstimator: 1752.53,
        isPending: false,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 2,
        fraudDataId: 3,
        caseManager: 'Michaela Kerasioti',
        investigator: 'Panos Georgiou',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.98,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 12453.070556640625,
        accidentProfile: '0',
        hcaReport: 'reports/326338223_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.51',
        created: null,
        updated: '2024-07-12T09:52:51.557',
        costEstimator: 11956.0,
        isPending: false,
        isApproved: false,
        investigationResults: [],
      },
      {
        caseId: 3,
        fraudDataId: 4,
        caseManager: 'Giorgos Papadopoulos',
        investigator: 'Maria Papadopoulou',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.84,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 1915.2199745178223,
        accidentProfile: '0',
        hcaReport: 'reports/4371139707_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.557',
        created: null,
        updated: '2024-07-12T08:29:34.97',
        costEstimator: 1935.38,
        isPending: false,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 4,
        fraudDataId: 5,
        caseManager: 'Giorgos Papadopoulos',
        investigator: 'Panos Georgiou',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.91,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 3204.4398803710938,
        accidentProfile: '1',
        hcaReport: 'reports/4371139717_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.62',
        created: null,
        updated: '2024-07-11T13:11:42.983',
        costEstimator: 1292.22,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 5,
        fraudDataId: 6,
        caseManager: 'Giorgos Papadopoulos',
        investigator: 'Panos Georgiou',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 2870.7200775146484,
        accidentProfile: '0',
        hcaReport: 'reports/4371139718_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.693',
        created: null,
        updated: '2024-07-09T17:13:41.95',
        costEstimator: 1762.96,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 6,
        fraudDataId: 7,
        caseManager: 'Giorgos Papadopoulos',
        investigator: 'Maria Papadopoulou',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.96,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 16.67,
        totalClaimAmount: 6425.890472412109,
        accidentProfile: '0',
        hcaReport: 'reports/326338365_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-09T17:29:44.863',
        costEstimator: 4886.16,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 7,
        fraudDataId: 9,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.71,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 1016.0599403381348,
        accidentProfile: '0',
        hcaReport: 'reports/326338393_el.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-09T13:59:09.553',
        costEstimator: 2254.33,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 8,
        fraudDataId: 11,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8689,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 2138.8999938964844,
        accidentProfile: '0',
        hcaReport: 'reports/326338409_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.827',
        created: null,
        updated: '2024-07-01T11:53:53.33',
        costEstimator: 1147.52,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 9,
        fraudDataId: 12,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9907,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 8428.619998931885,
        accidentProfile: '0',
        hcaReport: 'reports/326338299_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-01T11:50:20.203',
        costEstimator: 5773.35,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 10,
        fraudDataId: 13,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.93,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 3657.0899658203125,
        accidentProfile: '0',
        hcaReport: 'reports/326338370_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.897',
        created: null,
        updated: '2024-07-02T10:05:43.303',
        costEstimator: 2295.16,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 11,
        fraudDataId: 14,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.6859,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: 943.5799598693848,
        accidentProfile: '0',
        hcaReport: 'reports/326338409/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.967',
        created: null,
        updated: '2024-06-12T11:40:23.987',
        costEstimator: 703.24,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 12,
        fraudDataId: 15,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.95,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 1806.2099647521973,
        accidentProfile: '0',
        hcaReport: 'reports/326338373_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:28.057',
        created: null,
        updated: '2024-07-02T12:30:01.597',
        costEstimator: 488.43,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 13,
        fraudDataId: 16,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9686,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 8673.700164794922,
        accidentProfile: '0',
        hcaReport: 'reports/326338370/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.12',
        created: null,
        updated: '2024-06-12T11:40:23.987',
        costEstimator: 2061.92,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 14,
        fraudDataId: 17,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8554,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 2064.4300575256348,
        accidentProfile: '0',
        hcaReport: 'reports/4371139865_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:28.197',
        created: null,
        updated: '2024-06-14T13:13:16.78',
        costEstimator: 2061.92,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 15,
        fraudDataId: 18,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.99,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 7339.890045166016,
        accidentProfile: '0',
        hcaReport: 'reports/4371139871_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.263',
        created: null,
        updated: '2024-07-02T08:13:03.017',
        costEstimator: 542.28,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 16,
        fraudDataId: 19,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9668,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 7953.5706787109375,
        accidentProfile: '0',
        hcaReport: 'reports/4371139817/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.32',
        created: null,
        updated: '2024-06-12T11:40:23.987',
        costEstimator: 2503.52,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 17,
        fraudDataId: 20,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9692,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 9171,
        accidentProfile: '0',
        hcaReport: 'reports/4371139865_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.39',
        created: null,
        updated: '2024-06-12T11:40:23.987',
        costEstimator: 2503.52,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 18,
        fraudDataId: 21,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.84,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Outlier',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 888.649959564209,
        accidentProfile: '0',
        hcaReport: 'reports/326338306_el.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:28.453',
        created: null,
        updated: '2024-07-02T10:06:06.6',
        costEstimator: 550.65,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 19,
        fraudDataId: 22,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9937,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 11168.9599609375,
        accidentProfile: '0',
        hcaReport: 'reports/326338423/contracts_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.513',
        created: null,
        updated: '2024-06-12T11:40:23.987',
        costEstimator: 10408.72,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 20,
        fraudDataId: 23,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 275.189998626709,
        accidentProfile: '0',
        hcaReport: 'reports/326338444_el.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:28.577',
        created: null,
        updated: '2024-07-02T12:29:17.787',
        costEstimator: 301.91,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 21,
        fraudDataId: 24,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8863,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 2312.310028076172,
        accidentProfile: '0',
        hcaReport: 'reports/326338306/consumption_report.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:28.647',
        created: null,
        updated: '2024-07-02T09:17:12.04',
        costEstimator: 1317.89,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 22,
        fraudDataId: 25,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.89,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 2312.310028076172,
        accidentProfile: '0',
        hcaReport: 'reports/326338173_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:28.713',
        created: null,
        updated: '2024-07-02T12:52:49.527',
        costEstimator: 1317.89,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 23,
        fraudDataId: 26,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9666,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 8094.539855957031,
        accidentProfile: '0',
        hcaReport: 'reports/326338444/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.783',
        created: null,
        updated: '2024-06-12T11:40:24.067',
        costEstimator: 2360.17,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 24,
        fraudDataId: 27,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9135,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 3086.090118408203,
        accidentProfile: '0',
        hcaReport: 'reports/326338445/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.853',
        created: null,
        updated: '2024-06-12T11:40:24.067',
        costEstimator: 2741.52,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 25,
        fraudDataId: 28,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.92,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: null,
        accidentProfile: '0',
        hcaReport: 'reports/4371139960_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.923',
        created: null,
        updated: '2024-07-02T11:15:00.65',
        costEstimator: 2698.77,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 26,
        fraudDataId: 31,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.95,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 5479.580307006836,
        accidentProfile: '1',
        hcaReport: 'reports/4371139933_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:28.993',
        created: null,
        updated: '2024-07-02T09:19:13.283',
        costEstimator: 2698.77,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 27,
        fraudDataId: 32,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.46,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: null,
        accidentProfile: '0',
        hcaReport: 'reports/4371140038_el.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:29.077',
        created: null,
        updated: '2024-07-02T07:40:28.467',
        costEstimator: 504.58,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 28,
        fraudDataId: 33,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.3896,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 471.19997787475586,
        accidentProfile: '0',
        hcaReport: 'reports/4371139960/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.067',
        costEstimator: 550.65,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 29,
        fraudDataId: 35,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.98,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 12662.35986328125,
        accidentProfile: '0',
        hcaReport: 'reports/326338470/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.067',
        costEstimator: 8307.04,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 30,
        fraudDataId: 37,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8255,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: 993.8599243164062,
        accidentProfile: '0',
        hcaReport: 'reports/4371138985/contracts_report.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:29.133',
        created: null,
        updated: '2024-06-12T11:40:24.067',
        costEstimator: 889.34,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 31,
        fraudDataId: 38,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.97,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 8606.9404296875,
        accidentProfile: '0',
        hcaReport: 'reports/4371140094_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.217',
        created: null,
        updated: '2024-07-09T14:55:29.81',
        costEstimator: 3753.28,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 32,
        fraudDataId: 40,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 1,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 106412.3046875,
        accidentProfile: '0',
        hcaReport: 'reports/4371140103_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.32',
        created: null,
        updated: '2024-07-05T08:54:33.757',
        costEstimator: 79640.72,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 33,
        fraudDataId: 41,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9918,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 7799.669921875,
        accidentProfile: '0',
        hcaReport: 'reports/326338501_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-28T11:17:22.29',
        costEstimator: 8122.59,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 34,
        fraudDataId: 42,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9963,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 15425.830383300781,
        accidentProfile: '0',
        hcaReport: 'reports/4371140147_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.387',
        created: null,
        updated: '2024-06-27T09:18:43.15',
        costEstimator: 14439.49,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 35,
        fraudDataId: 45,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8367,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: 1659.020004272461,
        accidentProfile: '0',
        hcaReport: 'reports/326338542_el.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-27T09:31:23.427',
        costEstimator: 2957.28,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 36,
        fraudDataId: 47,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9909,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 7459.120422363281,
        accidentProfile: '0',
        hcaReport: 'reports/4371139844_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.48',
        created: null,
        updated: '2024-06-27T09:36:37.023',
        costEstimator: 7352.04,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 37,
        fraudDataId: 48,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.6972,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 979.7299842834473,
        accidentProfile: '0',
        hcaReport: 'reports/326338500/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:29.547',
        created: null,
        updated: '2024-06-12T11:40:24.07',
        costEstimator: 589.27,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 38,
        fraudDataId: 49,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8971,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 2857.7200775146484,
        accidentProfile: '0',
        hcaReport: 'reports/4371140094/contracts_report.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-28T08:01:58.8',
        costEstimator: 1762.96,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 39,
        fraudDataId: 52,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9186,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 3544.9799194335938,
        accidentProfile: '0',
        hcaReport: 'reports/4371140018_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.613',
        created: null,
        updated: '2024-06-14T10:39:09.903',
        costEstimator: 3370.82,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 40,
        fraudDataId: 53,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.3044,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 442.9100036621094,
        accidentProfile: '0',
        hcaReport: 'reports/4371140103/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:29.69',
        created: null,
        updated: '2024-06-14T10:39:09.903',
        costEstimator: 1006.57,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 41,
        fraudDataId: 57,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.6916,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 961.6300086975098,
        accidentProfile: '0',
        hcaReport: 'reports/326338501/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:29.76',
        created: null,
        updated: '2024-06-27T09:16:31.307',
        costEstimator: 2136.92,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 42,
        fraudDataId: 59,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9495,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 5399.710136413574,
        accidentProfile: '0',
        hcaReport: 'reports/326338582_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-27T09:38:13.653',
        costEstimator: 2808.3,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 43,
        fraudDataId: 60,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.4106,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 489.02001571655273,
        accidentProfile: '0',
        hcaReport: 'reports/4371140149/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-27T09:22:38.76',
        costEstimator: 570.18,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 44,
        fraudDataId: 61,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.8687,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 2238.7798919677734,
        accidentProfile: '0',
        hcaReport: 'reports/4371140152/contracts_report.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:29.827',
        created: null,
        updated: '2024-06-27T09:29:49.143',
        costEstimator: 1762.96,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 45,
        fraudDataId: 62,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.98,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 10914.949890136719,
        accidentProfile: '0',
        hcaReport: 'reports/326338643_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-12T08:31:07.23',
        costEstimator: 7080.51,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 46,
        fraudDataId: 65,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.98,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 3736.459915161133,
        accidentProfile: '0',
        hcaReport: 'reports/4371139653_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.893',
        created: null,
        updated: '2024-07-09T12:33:22.117',
        costEstimator: 590.36,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 47,
        fraudDataId: 66,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.98,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 13597.039672851562,
        accidentProfile: '0',
        hcaReport: 'reports/326338659_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.973',
        created: null,
        updated: '2024-07-09T13:58:13.057',
        costEstimator: 7579.12,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 48,
        fraudDataId: 68,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.688,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 950.060001373291,
        accidentProfile: '0',
        hcaReport: 'reports/326338565/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:30.037',
        created: null,
        updated: '2024-06-12T11:40:24.153',
        costEstimator: 3169.37,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 49,
        fraudDataId: 69,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.932,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 4034.9599609375,
        accidentProfile: '0',
        hcaReport: 'reports/4371140202/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.153',
        costEstimator: 2808.3,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 50,
        fraudDataId: 70,
        caseManager: 'Ioanna Karra',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.4169,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: null,
        accidentProfile: '0',
        hcaReport: 'reports/326338570/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.153',
        costEstimator: 504.58,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 51,
        fraudDataId: 72,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9243,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 3588.2000427246094,
        accidentProfile: '0',
        hcaReport: 'reports/326338589/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:30.113',
        created: null,
        updated: '2024-06-12T11:40:24.15',
        costEstimator: 3426.9,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 52,
        fraudDataId: 74,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.7495,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: null,
        accidentProfile: '0',
        hcaReport: 'reports/326338592/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:30.17',
        created: null,
        updated: '2024-06-12T11:40:24.15',
        costEstimator: 1223.23,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 53,
        fraudDataId: 76,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.6644,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 881.7199745178223,
        accidentProfile: '0',
        hcaReport: 'reports/326338601/contracts_report.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.153',
        costEstimator: 2061.92,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 54,
        fraudDataId: 77,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.829,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 1754.040033340454,
        accidentProfile: '0',
        hcaReport: 'reports/326338612/contracts_report.html',
        caseSeverity: 'Medium',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.15',
        costEstimator: 928.89,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 55,
        fraudDataId: 80,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.73,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 1090.3699989318848,
        accidentProfile: '0',
        hcaReport: 'reports/4371155863_el.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-14T10:41:59.38',
        costEstimator: 1314.29,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 56,
        fraudDataId: 81,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9312,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 4112.119979858398,
        accidentProfile: '0',
        hcaReport: 'reports/4371140262/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:30.23',
        created: null,
        updated: '2024-06-12T11:40:24.153',
        costEstimator: 2300.61,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 57,
        fraudDataId: 85,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9712,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: null,
        empiricalRulesScore: 50.0,
        totalClaimAmount: 8259.719970703125,
        accidentProfile: '0',
        hcaReport: 'reports/4371140342/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 8133.1,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 58,
        fraudDataId: 87,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9655,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 7517.389587402344,
        accidentProfile: '0',
        hcaReport: 'reports/4371140136/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:30.297',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 4948.88,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 59,
        fraudDataId: 88,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9748,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 10796.58964965865,
        accidentProfile: '0',
        hcaReport: 'reports/326338582/consumption_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:30.37',
        created: null,
        updated: '2024-06-27T09:37:45.667',
        costEstimator: 4723.57,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 60,
        fraudDataId: 90,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9982,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 58213.5029296875,
        accidentProfile: '0',
        hcaReport: 'reports/4371140320/cost_report.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:31.037',
        created: null,
        updated: '2024-06-12T11:40:24.217',
        costEstimator: 47272.92,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 61,
        fraudDataId: 91,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9783,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 4516.9997656252235,
        accidentProfile: '0',
        hcaReport: null,
        caseSeverity: 'High',
        started: '2023-08-28T02:10:31.097',
        created: null,
        updated: '2024-06-12T11:40:24.217',
        costEstimator: 4471.34,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 62,
        fraudDataId: 93,
        caseManager: ' ',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9642,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: null,
        empiricalRulesScore: 66.67,
        totalClaimAmount: 7698.539794921875,
        accidentProfile: '0',
        hcaReport: 'reports/326338643_el.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 6660.25,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 63,
        fraudDataId: 94,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.236,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 66.67,
        totalClaimAmount: 370.1499900817871,
        accidentProfile: '0',
        hcaReport: 'reports/4371140370.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 640.66,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 64,
        fraudDataId: 95,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9399,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 4530.8798828125,
        accidentProfile: '0',
        hcaReport: null,
        caseSeverity: 'High',
        started: '2023-08-28T02:10:31.217',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 4127.7,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 65,
        fraudDataId: 96,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.9259,
        claimSeverity: null,
        systemResult: 'NotSuspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 33.33,
        totalClaimAmount: 3788.669906616211,
        accidentProfile: '0',
        hcaReport: 'reports/2-139653.html',
        caseSeverity: 'High',
        started: '2023-08-28T02:10:31.157',
        created: null,
        updated: '2024-06-12T11:40:24.22',
        costEstimator: 3569.97,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 66,
        fraudDataId: 98,
        caseManager: 'Yannis Vasilakis',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.4558,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 83.33,
        totalClaimAmount: 533.1300086975098,
        accidentProfile: '0',
        hcaReport: 'reports/5-38659.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-01T13:33:19.157',
        costEstimator: 542.28,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 67,
        fraudDataId: 100,
        caseManager: 'Michaela Kerasioti',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.6751,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Regular',
        empiricalRulesScore: 50.0,
        totalClaimAmount: 943.7299902345985,
        accidentProfile: '1',
        hcaReport: 'reports/326338663.html',
        caseSeverity: 'Low',
        started: '2023-08-28T02:10:27.763',
        created: null,
        updated: '2024-07-02T07:02:02.457',
        costEstimator: 471.87,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 78,
        fraudDataId: 5217,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.985,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 25.0,
        totalClaimAmount: 9190.464645,
        accidentProfile: '0',
        hcaReport: null,
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.893',
        created: null,
        updated: '2024-06-27T09:33:07.467',
        costEstimator: 19597.2,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 79,
        fraudDataId: 5218,
        caseManager: 'Giorgos Papadopoulos',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.992,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Extreme Outlier',
        empiricalRulesScore: 75.0,
        totalClaimAmount: 15518.4147166,
        accidentProfile: '1',
        hcaReport: null,
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.893',
        created: null,
        updated: '2024-06-27T09:33:07.467',
        costEstimator: 35981.6,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
      {
        caseId: 80,
        fraudDataId: 5219,
        caseManager: 'Michaela Kerasioti',
        investigator: ' ',
        dataModelScore: null,
        dataModelUnsupervised: null,
        claimAmount: null,
        priorityIndex: 0.96,
        claimSeverity: null,
        systemResult: 'Suspicious',
        investigationResult: null,
        anomalyDetection: 'Outlier',
        empiricalRulesScore: 85.0,
        totalClaimAmount: 7309.5192867,
        accidentProfile: '0',
        hcaReport: null,
        caseSeverity: 'High',
        started: '2023-08-28T02:10:29.893',
        created: null,
        updated: '2024-07-11T13:47:16.933',
        costEstimator: 5432.0,
        isPending: true,
        isApproved: null,
        investigationResults: [],
      },
    ]);
  }
  getClaimsv2(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}claims/all`,
      this.httpOptions
    );
  }
  getClaimsByClaimId(claimId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}claims/${{ claimId }}`,
      this.httpOptions
    );
  }
  updateClaimById(claimId:number): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}claim/${{claimId}}/update`,
      this.httpOptions
    );
  }

  createClaim(claim:any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}claim/new`,claim,
      this.httpOptions
    );
  }

  deleteClaimById(claimId:number): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}claim/${{claimId}}/delete`,
      this.httpOptions
    );
  }

}
