import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@shared/environments';
import { MemberService } from '../member/member.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

interface AuthRegister {
  username: string;
  firstname: string;
  lastname: string;
  password: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly memberService = inject(MemberService);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  private apiUrl = `${environment.api}users`;
  private authToken: string | null = null;

  private currentUserSubject = new ReplaySubject<any>(1);
  currentUser$ = this.currentUserSubject.asObservable();

  fetchUser(token: any) {
    // debugger;
    if (token == null) {
      this.currentUserSubject.next(null);
      return of(null);
    }

    return this.memberService.getEntityByUsername(token).pipe(
      map((user) => {
        // console.log('Fetched', user);
        this.currentUserSubject.next(user);
        return user;
      }),
      catchError((err) => {
        this.logout();
        console.error(err);
        return of(undefined);
      })
    );
  }

  private getAuthTokenFromLocalStorage(): string | null {
    const tokenJson = localStorage.getItem('token');
    return tokenJson ? JSON.parse(tokenJson) : null;
  }

  private saveAuthTokenToLocalStorage(token: string): void {
    const localStorageToken = { token: token };
    localStorage.setItem('token', JSON.stringify(localStorageToken));
  }

  private clearAuthToken(): void {
    localStorage.removeItem('token');
  }

  login(email: string, password: string): Observable<any> {
    return this.http
      .post<string>(`${this.apiUrl}/login`, {
        email: email,
        password: password,
      })
      .pipe(
        switchMap((token) => {
          // // console.log(token);
          this.authToken = token;
          // debugger;
          this.saveAuthTokenToLocalStorage(token);

          // Chain the fetchUser observable
          return this.fetchUser(token).pipe(
            map(() => token) // Return the token after fetchUser completes
          );
        })
      );
  }
  register(createUser: AuthRegister): Observable<any> {
    return this.http.post<string>(`${this.apiUrl}/register`, createUser);
  }
  logout(): void {
    this.clearAuthToken();
    this.authToken = null;
    this.currentUserSubject.next(null);
    this.router.navigate(['login']);
  }

  setUser(user: any) {
    this.currentUserSubject.next(user);
  }

  isAuthenticated(): boolean {
    const token = this.authToken || this.getAuthTokenFromLocalStorage();
    return token ? !this.isTokenExpired(token) : false;
  }

  private isTokenExpired(token: string): boolean {
    return false;
    // try {
    //   const expiry = new Date(token.timeStamp).getTime() / 1000;
    //   return Math.floor(new Date().getTime() / 1000) >= expiry;
    // } catch (e) {
    //   console.error('Error parsing token:', e);
    //   return true;
    // }
  }
}
