import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService extends BaseService {
  getInsurances(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}insurances/`, this.httpOptions);
  }
  getInsurancesById(insuranceId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}insurances/${insuranceId}`,
      this.httpOptions
    );
  }
  getInsuranceDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}insurances/me`, this.httpOptions);
  }
  getInsuranceProductsByInsurerId(insurerId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}insurances/${insurerId}/products`,
      this.httpOptions
    );
  }
  getClaims(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}claims`, this.httpOptions);
  }
  getClaimsById(claimId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}claims/${claimId}`,
      this.httpOptions
    );
  }
}
