@if (!acceptedLocationPermission) {
  <div class="col-12 text-center text-red-500">
    <p>{{ error }}</p>
    @if (error.includes('blocked')) {
      <p>
        Please enable location access in your browser settings and refresh the page.
      </p>
    }
  </div>
}

@if (displayMap) {
  <div
    id="map"
    style="height: 500px"
  ></div>
}

<div id="popup" class="ol-popup">
  @if(selectedMarker && selectedAddress){
    <div class="doctor-details">
      <div class="header flex align-items-center justify-content-between mb-3">
        <div class="text-xl text-primary font-bold flex align-items-center">
          <span class="p-badge text-white p-badge-no-gutter mr-2">
            <i class="pi pi-info" style="font-size: x-small"></i>
          </span>
          Πληροφορίες
        </div>
        <i
          class="pi pi-times"
          id="popup-closer"
          class="ol-popup-closer"
          (click)="closeDetails()"
        ></i>
      </div>
      <p>
        <strong>
          <i class="pi pi-user mr-2"></i>
          {{ selectedMarker?.fullName ?? selectedMarker?.name }}
        </strong>
      </p>
      @if(selectedMarker?.specialty){
        <p>
          <strong>
            <i class="pi pi-briefcase mr-2"></i>
            {{ selectedMarker?.specialty?.name }}
          </strong>
        </p>
      }
      <p>
        <strong>
          <i class="pi pi-map-marker mr-2"></i> {{ selectedAddress.fullAddress }}
        </strong>
      </p>
      <div class="flex justify-content-center mt-3">
        <button
          type="button"
          class="p-button"
          (click)="navigateToDetails(selectedMarker)"
          >
          Κλείσε Ραντεβού
        </button>
      </div>
    </div>
  }
</div>
