import { Component, inject } from '@angular/core';
import { LoadingIndicatorService } from '../../services/shared/loading-indicator.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'lib-loading-spinner',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './loading-spinner.component.html',
  styleUrl: './loading-spinner.component.css',
})
export class LoadingSpinnerComponent {
  public indicatorService = inject(LoadingIndicatorService);
}
