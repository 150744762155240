import { OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuService } from './app.menu.service';
import { MenuDataService } from './service/menu-data.service';

import { AppMenuitemComponent } from './app.menuitem.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: true,
    imports: [
    AppMenuitemComponent
],
})
export class AppMenuComponent implements OnInit {
  layoutService = inject(LayoutService);
  private menuDataService = inject(MenuDataService);

  model: any[] = [];

  ngOnInit() {
    this.model = this.menuDataService.selectedValue;
  }
}
