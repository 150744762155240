<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger transition-duration-300"
      (click)="onMenuButtonClick()"
      >
      <i class="pi pi-bars"></i>
    </button>
  </div>
  <div class="layout-topbar-menu-section">
    <app-sidebar></app-sidebar>
  </div>
  <div class="topbar-end">
    <ul class="topbar-menu">
      <!-- <li [class]="layoutService.isHorizontal() ? 'topbar-search hidden' : 'topbar-search hidden sm:block'">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full" />
      </span>
    </li> -->
    <!-- <li [class]="layoutService.isHorizontal() ? 'block topbar-item' : 'block sm:hidden topbar-item'">
    <a pStyleClass="@next" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
      <i class="pi pi-search"></i>
    </a>
    <ul [class]="'ng-hidden topbar-menu active-topbar-menu p-3 w-15rem  z-5'" style="bottom: -5.8rem">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search"></i>
        <input type="text" pInputText placeholder="Search" class="w-full" />
      </span>
    </ul>
  </li> -->

  @if(appName &&( appName == 'insured' || appName == 'doctor')){
    <li class="topbar-item">
      <button
        pButton
        type="button"
        (click)="goToInbox()"
        pTooltip="{{ 'TOOLTIP.TOPBAR.INBOX' | translate }}"
        tooltipPosition="bottom"
        [style]="{ all: 'unset', cursor: 'pointer' }"
        class="p-button-text p-button-secondary mr-2 flex-shrink-0"
        >
        <i
          pbadge=""
          class="p-element pi pi-inbox p-overlay-badge"
          style="font-size: 1.4rem"
          >
          @if(messageCount>0){
            <span
              #notification
              class="p-badge p-component p-badge-no-gutter"
              style="background: var(--primary-color); color: white"
              >{{ messageCount }}</span
              >
            }
          </i>
        </button>
      </li>
    }
    <li class="topbar-item">
      <button
        pButton
        type="button"
        (click)="showSidebar()"
        pTooltip="{{ 'TOOLTIP.TOPBAR.NOTIFICATIONS' | translate }}"
        tooltipPosition="bottom"
        [style]="{ all: 'unset', cursor: 'pointer' }"
        class="p-button-text p-button-secondary flex-shrink-0"
        >
        <i
          pbadge=""
          class="p-element pi pi-bell p-overlay-badge"
          style="font-size: 1.4rem"
          >
          @if(notificationCount>0){
            <span
              #notification
              class="p-badge p-component p-badge-no-gutter"
              style="background: var(--primary-color); color: white"
              >{{ notificationCount }}</span
              >
            }
          </i>
        </button>
      </li>
      <li class="topbar-item">
        <button
          pButton
          type="button"
          [icon]="themeMode"
          class="p-button-text p-button-secondary flex-shrink-0"
          (click)="changeThemeMode()"
          pTooltip="{{ 'TOOLTIP.TOPBAR.CHANGE_THEME' | translate }}"
          tooltipPosition="bottom"
        ></button>
      </li>
      <!-- <button (click)="switchLanguage('en')">English</button>
      <button (click)="switchLanguage('gr')">Ελληνικά</button> -->
      <li class="topbar-item">
        <div>
          <button
            type="button"
            (click)="toggleDropdown()"
            pTooltip="{{ 'TOOLTIP.TOPBAR.SELECT_LANGUAGE' | translate }}"
            tooltipPosition="bottom"
            style="
              border: none;
              background: transparent;
              cursor: pointer;
              padding: 0;
            "
            >
            <img
              [src]="selectedLanguageIcon"
              alt="Selected Language"
              style="width: 20px; height: auto"
              />
            </button>

            @if (dropdownVisible) {
              <div
                class="dropdown-menu"
                style="position: absolute; z-index: 1000"
                >
                @for (lang of languages; track lang) {
                  <div
                    (click)="switchLanguage(lang.value)"
                style="
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  padding: 5px;
                "
                    >
                    <img
                      [src]="lang.icon"
                      alt="{{ lang.label }}"
                      style="width: 15px; height: auto; margin-right: 2px"
                      />
                      {{ lang.label }}
                    </div>
                  }
                </div>
              }
            </div>
          </li>

          <li class="topbar-item">
            <a
              pStyleClass="@next"
              enterFromClass="ng-hidden"
              enterActiveClass="px-scalein"
              leaveToClass="ng-hidden"
              leaveActiveClass="px-fadeout"
              [hideOnOutsideClick]="true"
              pRipple
              class="cursor-pointer"
              >
              <img
                class="border-round-xl"
                src="assets/demo/images/avatar/circle/avatar-m-2.png"
                alt="Profile"
                />
              </a>
              <ul
                [class]="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 ng-hidden'"
                >
                <!-- <li role="menuitem" class="m-0 mb-3">
                <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                  <i class="pi pi-fw pi-lock mr-2"></i>
                  <span>Privacy</span>
                </a>
              </li> -->
              <li role="menuitem" class="m-0 mb-3">
                <div
                  href="#"
                  class="flex align-items-center hover:text-primary-500 transition-duration-200 cursor-pointer"
                  pStyleClass="@grandparent"
                  enterFromClass="ng-hidden"
                  enterActiveClass="px-scalein"
                  leaveToClass="ng-hidden"
                  leaveActiveClass="px-fadeout"
                  (click)="goToSettings()"
                  >
                  <i class="pi pi-fw pi-cog mr-2"></i>
                  <span>Settings</span>
                </div>
              </li>
              <li role="menuitem" class="m-0">
                <a
                  (click)="logout()"
                  href="#"
                  class="flex align-items-center hover:text-primary-500 transition-duration-200"
                  pStyleClass="@grandparent"
                  enterFromClass="ng-hidden"
                  enterActiveClass="px-scalein"
                  leaveToClass="ng-hidden"
                  leaveActiveClass="px-fadeout"
                  >
                  <i class="pi pi-fw pi-sign-out mr-2"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="topbar-item">
          <button
            pButton
            type="button"
            icon="pi pi-sign-out"
            class="p-button-text p-button-secondary flex-shrink-0"
            (click)="logout()"
            pTooltip="{{ 'TOOLTIP.TOPBAR.LOGOUT' | translate }}"
            tooltipPosition="bottom"
          ></button>
        </li> -->
      </ul>
    </div>
  </div>
  <p-toast [life]="3000">
    <ng-template let-msg pTemplate="message">
      <div class="custom-toast" (click)="showSidebar()">
        <div class="custom-header">{{ msg.message }}</div>
        <div class="custom-detail">{{ msg.detail }}</div>
      </div>
    </ng-template></p-toast
    >
    <p-sidebar
      position="right"
      [(visible)]="sidebarVisible"
      (onHide)="onSidebarClose()"
      >
      @if(notificationCount>0){
        <button
          pButton
          (click)="clearAllNotifications()"
          class="bg-gray-700 text-white"
          >
          Καθαρισμός Όλων</button
          >}
          <ng-template pTemplate="content">
            <div class="flex flex-column">
              @for(message of newNotifications; track message){

                <p-messages [severity]="message.severity">
                  <ng-template pTemplate>
                    <div class="flex flex-row align-items-center gap-3 w-full">
                      <div
                        class="text-xl font-bold cursor-pointer"
                        (click)="discardMessage(message)"
                        >
                        X
                      </div>
                      <div
                        class="text-wrap cursor-pointer"
                        (click)="router.navigateByUrl('/calendar')"
                        >
                        {{ message.detail }}
                      </div>
                    </div>
                  </ng-template>
                </p-messages>
              }
            </div>
          </ng-template>

          <!-- }@else {
          <div class="text-center text-lg">Καμία νέα ειδοποίηση!</div>
          } -->
        </p-sidebar>
