import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { environment } from '../../../../insured-app/src/environments/environment';

export interface Incidentclaims {
  incident: string;
  claimVolume: number;
  claimVolumePercentage: number;
  avgPrice: number;
}

export interface MedicalExpensesProvider {
  healthProvider: string;
  medicalSuppliesPrice: number;
  specialMedicalSuppliesPrice: number;
  hospitalizationPrice: number;
  medicalExaminationsPrice: number;
  surgeryExpensesPrice: number;
  physicianFeesPrice: number;
  otherExpensesPrice: number;
  totalExpensesPrice: number;
}
export interface ProviderSummary {
  drg: string;
  healthProvider: string;
  minPrice: number;
  avgPrice: number;
  maxPrice: number;
  minHospitalizationDays: number;
  avgHospitalizationDays: number;
  maxHospitalizationDays: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardDataService {
  private http = inject(HttpClient);

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  getDateClaim(): Observable<any> {
    return of([
      {
        title: '2023-01-31',
        amount: 250,
      },
      {
        title: '2023-02-29',
        amount: 190,
      },
      {
        title: '2023-03-31',
        amount: 320,
      },
      {
        title: '2023-04-30',
        amount: 290,
      },
      {
        title: '2023-05-31',
        amount: 210,
      },
      {
        title: '2023-06-30',
        amount: 190,
      },
      {
        title: '2023-07-31',
        amount: 300,
      },
      {
        title: '2023-08-30',
        amount: 230,
      },
      {
        title: '2023-09-31',
        amount: 230,
      },
    ]);
  }

  getAccomodation(): Observable<any> {
    return of([
      {
        title: 'Single Bed',
        amount: 11.5,
      },
      {
        title: 'Double Bed',
        amount: 27.2,
      },
      {
        title: 'Triple  Bed',
        amount: 11.6,
      },
      {
        title: 'ODC',
        amount: 18.9,
      },
      {
        title: 'ODS',
        amount: 16.5,
      },
      {
        title: 'OTHER',
        amount: 14.3,
      },
    ]);
  }

  getProviderClaims(): Observable<any> {
    return of([
      {
        healthProvider: 'Hospital C',
        claimVolume: 980,
        claimVolumePercentage: 42.794759825327,
        totalClaimsPrice: 8198020.702450882,
        totalClaimsPercentage: 54.050638156660064,
      },
      {
        healthProvider: 'Hospital B',
        claimVolume: 938,
        claimVolumePercentage: 40.960698689956,
        totalClaimsPrice: 4864727.972015381,
        totalClaimsPercentage: 32.07379694313006,
      },
      {
        healthProvider: 'Hospital A',
        claimVolume: 372,
        claimVolumePercentage: 16.244541484716,
        totalClaimsPrice: 2104548.1087646484,
        totalClaimsPercentage: 13.875564900209866,
      },
    ]);
  }

  getDRGSummaryProvider(): Observable<any> {
    return of([
      {
        drg: 'Α01Χ',
        healthProvider: 'Hospital A',
        minPrice: 8306.5595703125,
        avgPrice: 8306.5595703125,
        maxPrice: 8306.5595703125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Α01Χ',
        healthProvider: 'Hospital C',
        minPrice: 7833.33984375,
        avgPrice: 26503.182698567707,
        maxPrice: 83456.5390625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 11,
      },
      {
        drg: 'Α12Α',
        healthProvider: 'Hospital C',
        minPrice: 2616.639892578125,
        avgPrice: 4062.7799682617188,
        maxPrice: 6471.25,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Α12Χ',
        healthProvider: 'Hospital C',
        minPrice: 8360.9404296875,
        avgPrice: 8360.9404296875,
        maxPrice: 8360.9404296875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Α21Χ',
        healthProvider: 'Hospital A',
        minPrice: 3638.340087890625,
        avgPrice: 3638.340087890625,
        maxPrice: 3638.340087890625,
        minHospitalizationDays: 6,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Α21Χ',
        healthProvider: 'Hospital C',
        minPrice: 3721.2900390625,
        avgPrice: 7715.3134765625,
        maxPrice: 11605.98046875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Α22ΜΑ',
        healthProvider: 'Hospital A',
        minPrice: 32348.58984375,
        avgPrice: 32348.58984375,
        maxPrice: 32348.58984375,
        minHospitalizationDays: 30,
        avgHospitalizationDays: 30,
        maxHospitalizationDays: 30,
      },
      {
        drg: 'Α22ΜΒ',
        healthProvider: 'Hospital A',
        minPrice: 10130.1201171875,
        avgPrice: 10130.1201171875,
        maxPrice: 10130.1201171875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Α22ΜΒ',
        healthProvider: 'Hospital C',
        minPrice: 9578.419921875,
        avgPrice: 9578.419921875,
        maxPrice: 9578.419921875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Α22Χ',
        healthProvider: 'Hospital A',
        minPrice: 3080.159912109375,
        avgPrice: 5035.365532769098,
        maxPrice: 11102.9599609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Α22Χ',
        healthProvider: 'Hospital B',
        minPrice: 656.27001953125,
        avgPrice: 3528.856311035156,
        maxPrice: 10217.91015625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Α22Χ',
        healthProvider: 'Hospital C',
        minPrice: 3049.580078125,
        avgPrice: 9013.360037667411,
        maxPrice: 35849.2109375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 17,
      },
      {
        drg: 'Α23Α',
        healthProvider: 'Hospital A',
        minPrice: 550.1099853515625,
        avgPrice: 550.1099853515625,
        maxPrice: 550.1099853515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Α23Α',
        healthProvider: 'Hospital B',
        minPrice: 828.8900146484375,
        avgPrice: 1353.5259765625,
        maxPrice: 3149.669921875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Α23Α',
        healthProvider: 'Hospital C',
        minPrice: 85.93000030517578,
        avgPrice: 1449.268177726052,
        maxPrice: 7089.2900390625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Α26Χ',
        healthProvider: 'Hospital B',
        minPrice: 2432.2099609375,
        avgPrice: 2432.2099609375,
        maxPrice: 2432.2099609375,
        minHospitalizationDays: 9,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Α26Χ',
        healthProvider: 'Hospital C',
        minPrice: 3559.9599609375,
        avgPrice: 6560.263346354167,
        maxPrice: 11400.169921875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Α27Χ',
        healthProvider: 'Hospital A',
        minPrice: 1731.4000244140625,
        avgPrice: 2090.1150512695312,
        maxPrice: 2448.830078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Α27Χ',
        healthProvider: 'Hospital B',
        minPrice: 374.4100036621094,
        avgPrice: 5211.168370564778,
        maxPrice: 11954.9501953125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Α27Χ',
        healthProvider: 'Hospital C',
        minPrice: 2270.06005859375,
        avgPrice: 3865.9971749441966,
        maxPrice: 6932.27978515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Α28Χ',
        healthProvider: 'Hospital A',
        minPrice: 3835.1201171875,
        avgPrice: 3835.1201171875,
        maxPrice: 3835.1201171875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Α28Χ',
        healthProvider: 'Hospital C',
        minPrice: 5193.02001953125,
        avgPrice: 5193.02001953125,
        maxPrice: 5193.02001953125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Α29Χ',
        healthProvider: 'Hospital B',
        minPrice: 1000.3200073242188,
        avgPrice: 2204.128755569458,
        maxPrice: 4848.64013671875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Α30Χ',
        healthProvider: 'Hospital B',
        minPrice: 1292.3399658203125,
        avgPrice: 1292.3399658203125,
        maxPrice: 1292.3399658203125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Α33ΜΒ',
        healthProvider: 'Hospital C',
        minPrice: 9759.75,
        avgPrice: 13715.5546875,
        maxPrice: 17671.359375,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Α33Χ',
        healthProvider: 'Hospital B',
        minPrice: 7992.2998046875,
        avgPrice: 7992.2998046875,
        maxPrice: 7992.2998046875,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Α33Χ',
        healthProvider: 'Hospital C',
        minPrice: 1933.760009765625,
        avgPrice: 1933.760009765625,
        maxPrice: 1933.760009765625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Α34Χ',
        healthProvider: 'Hospital C',
        minPrice: 4082.6201171875,
        avgPrice: 5565.81005859375,
        maxPrice: 7049,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Β02Χ',
        healthProvider: 'Hospital A',
        minPrice: 2982.699951171875,
        avgPrice: 4354.058349609375,
        maxPrice: 5923.330078125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Β02Χ',
        healthProvider: 'Hospital B',
        minPrice: 4662.60986328125,
        avgPrice: 6629.716471354167,
        maxPrice: 8365.849609375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Β02Χ',
        healthProvider: 'Hospital C',
        minPrice: 6652.77978515625,
        avgPrice: 7723.974951171875,
        maxPrice: 8422.240234375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Β04Α',
        healthProvider: 'Hospital A',
        minPrice: 1978.969970703125,
        avgPrice: 2475.0675048828125,
        maxPrice: 3028.2099609375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Β04Α',
        healthProvider: 'Hospital B',
        minPrice: 2137.97998046875,
        avgPrice: 3386.9286063058034,
        maxPrice: 4763,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Β04Α',
        healthProvider: 'Hospital C',
        minPrice: 968.3599853515625,
        avgPrice: 4356.083353678386,
        maxPrice: 6065.43994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Β05Α',
        healthProvider: 'Hospital B',
        minPrice: 2509.510009765625,
        avgPrice: 2879.9749755859375,
        maxPrice: 3250.43994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Β05Α',
        healthProvider: 'Hospital C',
        minPrice: 3495.550048828125,
        avgPrice: 4160.3200276692705,
        maxPrice: 4919.31005859375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Β20Χ',
        healthProvider: 'Hospital A',
        minPrice: 1976.6500244140625,
        avgPrice: 1976.6500244140625,
        maxPrice: 1976.6500244140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Β20Χ',
        healthProvider: 'Hospital B',
        minPrice: 4658.2001953125,
        avgPrice: 5038.39501953125,
        maxPrice: 5418.58984375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Β20Χ',
        healthProvider: 'Hospital C',
        minPrice: 3061.06005859375,
        avgPrice: 14276.301513671875,
        maxPrice: 19439.6796875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Β22Α',
        healthProvider: 'Hospital B',
        minPrice: 18917.9609375,
        avgPrice: 18917.9609375,
        maxPrice: 18917.9609375,
        minHospitalizationDays: 12,
        avgHospitalizationDays: 12,
        maxHospitalizationDays: 12,
      },
      {
        drg: 'Β22Α',
        healthProvider: 'Hospital C',
        minPrice: 4606.169921875,
        avgPrice: 5723.239990234375,
        maxPrice: 6840.31005859375,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Γ04Χ',
        healthProvider: 'Hospital A',
        minPrice: 8144.64990234375,
        avgPrice: 8144.64990234375,
        maxPrice: 8144.64990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ04Χ',
        healthProvider: 'Hospital B',
        minPrice: 8066.10986328125,
        avgPrice: 10943.08779296875,
        maxPrice: 20619.19921875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Γ04Χ',
        healthProvider: 'Hospital C',
        minPrice: 11064.169921875,
        avgPrice: 11064.169921875,
        maxPrice: 11064.169921875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ05Χ',
        healthProvider: 'Hospital A',
        minPrice: 7548.8701171875,
        avgPrice: 7548.8701171875,
        maxPrice: 7548.8701171875,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Γ06Χ',
        healthProvider: 'Hospital B',
        minPrice: 4066.419921875,
        avgPrice: 7544.394775390625,
        maxPrice: 12218.599609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Γ07Α',
        healthProvider: 'Hospital A',
        minPrice: 10231.9404296875,
        avgPrice: 10231.9404296875,
        maxPrice: 10231.9404296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ07Α',
        healthProvider: 'Hospital B',
        minPrice: 507.8900146484375,
        avgPrice: 5546.728002929687,
        maxPrice: 9245.080078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Γ07Α',
        healthProvider: 'Hospital C',
        minPrice: 5012.58984375,
        avgPrice: 5012.58984375,
        maxPrice: 5012.58984375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ08Α',
        healthProvider: 'Hospital A',
        minPrice: 3212.02001953125,
        avgPrice: 3212.02001953125,
        maxPrice: 3212.02001953125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Γ08Α',
        healthProvider: 'Hospital B',
        minPrice: 2148.77001953125,
        avgPrice: 7514.010779747596,
        maxPrice: 12906.919921875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Γ08Α',
        healthProvider: 'Hospital C',
        minPrice: 9117.259765625,
        avgPrice: 9117.259765625,
        maxPrice: 9117.259765625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ09Α',
        healthProvider: 'Hospital B',
        minPrice: 425.42999267578125,
        avgPrice: 2124.354287283761,
        maxPrice: 4762.0400390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ09Α',
        healthProvider: 'Hospital C',
        minPrice: 2322.89990234375,
        avgPrice: 2322.89990234375,
        maxPrice: 2322.89990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ10Α',
        healthProvider: 'Hospital B',
        minPrice: 955.27001953125,
        avgPrice: 1107.9737701416016,
        maxPrice: 1358.719970703125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ11Α',
        healthProvider: 'Hospital C',
        minPrice: 9267.3701171875,
        avgPrice: 9267.3701171875,
        maxPrice: 9267.3701171875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Γ12Χ',
        healthProvider: 'Hospital B',
        minPrice: 9934.58984375,
        avgPrice: 11845.509765625,
        maxPrice: 14479.7197265625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Γ20Χ',
        healthProvider: 'Hospital B',
        minPrice: 10424.990234375,
        avgPrice: 10884.6298828125,
        maxPrice: 11344.26953125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Γ20Χ',
        healthProvider: 'Hospital C',
        minPrice: 3652.56005859375,
        avgPrice: 3652.56005859375,
        maxPrice: 3652.56005859375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Γ21Α',
        healthProvider: 'Hospital B',
        minPrice: 4033.52001953125,
        avgPrice: 4033.52001953125,
        maxPrice: 4033.52001953125,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Γ21Α',
        healthProvider: 'Hospital C',
        minPrice: 4706.75,
        avgPrice: 4706.75,
        maxPrice: 4706.75,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Γ22Α',
        healthProvider: 'Hospital A',
        minPrice: 3457.169921875,
        avgPrice: 3457.169921875,
        maxPrice: 3457.169921875,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Γ22Α',
        healthProvider: 'Hospital B',
        minPrice: 455.7799987792969,
        avgPrice: 1684.6299830845423,
        maxPrice: 2585.989990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Γ22Α',
        healthProvider: 'Hospital C',
        minPrice: 2003.719970703125,
        avgPrice: 2003.719970703125,
        maxPrice: 2003.719970703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ06Α',
        healthProvider: 'Hospital A',
        minPrice: 4295.7900390625,
        avgPrice: 4295.7900390625,
        maxPrice: 4295.7900390625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ06Α',
        healthProvider: 'Hospital B',
        minPrice: 9057.91015625,
        avgPrice: 12573.901953125,
        maxPrice: 17707.1796875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Δ06Α',
        healthProvider: 'Hospital C',
        minPrice: 592.9299926757812,
        avgPrice: 592.9299926757812,
        maxPrice: 592.9299926757812,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Δ07Α',
        healthProvider: 'Hospital A',
        minPrice: 1250.4000244140625,
        avgPrice: 3028.207504272461,
        maxPrice: 4017.760009765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ07Α',
        healthProvider: 'Hospital B',
        minPrice: 4921.9599609375,
        avgPrice: 7423.352213541667,
        maxPrice: 9047.75,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ07Α',
        healthProvider: 'Hospital C',
        minPrice: 2027.1700439453125,
        avgPrice: 6163.029983520508,
        maxPrice: 8281.8701171875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Δ08Μ',
        healthProvider: 'Hospital C',
        minPrice: 16992.060546875,
        avgPrice: 16992.060546875,
        maxPrice: 16992.060546875,
        minHospitalizationDays: 13,
        avgHospitalizationDays: 13,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Δ09Α',
        healthProvider: 'Hospital A',
        minPrice: 2517.610107421875,
        avgPrice: 2517.610107421875,
        maxPrice: 2517.610107421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ09Α',
        healthProvider: 'Hospital B',
        minPrice: 4436.3798828125,
        avgPrice: 4436.3798828125,
        maxPrice: 4436.3798828125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ09Α',
        healthProvider: 'Hospital C',
        minPrice: 268.9700012207031,
        avgPrice: 3746.69895324707,
        maxPrice: 4590.31982421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ11Α',
        healthProvider: 'Hospital A',
        minPrice: 1804.06005859375,
        avgPrice: 1804.06005859375,
        maxPrice: 1804.06005859375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ11Α',
        healthProvider: 'Hospital B',
        minPrice: 1944.550048828125,
        avgPrice: 1944.550048828125,
        maxPrice: 1944.550048828125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ11Α',
        healthProvider: 'Hospital C',
        minPrice: 938.75,
        avgPrice: 1475.1400146484375,
        maxPrice: 2011.530029296875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ23Χ',
        healthProvider: 'Hospital B',
        minPrice: 567.7999877929688,
        avgPrice: 4420.688344319661,
        maxPrice: 7126.240234375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ23Χ',
        healthProvider: 'Hospital C',
        minPrice: 1983.3199462890625,
        avgPrice: 3890.0421685112847,
        maxPrice: 5038.02978515625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ24Χ',
        healthProvider: 'Hospital B',
        minPrice: 1088.300048828125,
        avgPrice: 5188.08544921875,
        maxPrice: 13109.01953125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Δ24Χ',
        healthProvider: 'Hospital C',
        minPrice: 407.239990234375,
        avgPrice: 5411.536083984375,
        maxPrice: 11311.330078125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Δ25Χ',
        healthProvider: 'Hospital B',
        minPrice: 1299.43994140625,
        avgPrice: 1299.43994140625,
        maxPrice: 1299.43994140625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ27Χ',
        healthProvider: 'Hospital A',
        minPrice: 1029.5400390625,
        avgPrice: 2735.5850524902344,
        maxPrice: 6210.93017578125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Δ27Χ',
        healthProvider: 'Hospital B',
        minPrice: 2426.669921875,
        avgPrice: 3839.460009765625,
        maxPrice: 4665.85986328125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ27Χ',
        healthProvider: 'Hospital C',
        minPrice: 938.530029296875,
        avgPrice: 2815.782516479492,
        maxPrice: 5922.31982421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ28Α',
        healthProvider: 'Hospital C',
        minPrice: 70.47000122070312,
        avgPrice: 2912.8824501037598,
        maxPrice: 4553.68994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ28Χ',
        healthProvider: 'Hospital C',
        minPrice: 6342.10986328125,
        avgPrice: 6342.10986328125,
        maxPrice: 6342.10986328125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ29Α',
        healthProvider: 'Hospital A',
        minPrice: 1689.9000244140625,
        avgPrice: 1731.0800170898438,
        maxPrice: 1772.260009765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Δ29Α',
        healthProvider: 'Hospital C',
        minPrice: 8979.7197265625,
        avgPrice: 8979.7197265625,
        maxPrice: 8979.7197265625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Δ29Χ',
        healthProvider: 'Hospital B',
        minPrice: 10798.9404296875,
        avgPrice: 10798.9404296875,
        maxPrice: 10798.9404296875,
        minHospitalizationDays: 13,
        avgHospitalizationDays: 13,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Ζ22Χ',
        healthProvider: 'Hospital B',
        minPrice: 1576.469970703125,
        avgPrice: 1576.469970703125,
        maxPrice: 1576.469970703125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Η01Χ',
        healthProvider: 'Hospital A',
        minPrice: 19683.5703125,
        avgPrice: 19683.5703125,
        maxPrice: 19683.5703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Η01Χ',
        healthProvider: 'Hospital B',
        minPrice: 18310.830078125,
        avgPrice: 36715.430338541664,
        maxPrice: 60772.23046875,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 8,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Η05Χ',
        healthProvider: 'Hospital B',
        minPrice: 13988.75,
        avgPrice: 13988.75,
        maxPrice: 13988.75,
        minHospitalizationDays: 9,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Η07Χ',
        healthProvider: 'Hospital A',
        minPrice: 9902.8203125,
        avgPrice: 10165.14013671875,
        maxPrice: 10427.4599609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Η08Χ',
        healthProvider: 'Hospital A',
        minPrice: 3957.169921875,
        avgPrice: 6476.743326822917,
        maxPrice: 9280.330078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Η08Χ',
        healthProvider: 'Hospital B',
        minPrice: 5580.43017578125,
        avgPrice: 10055.171203613281,
        maxPrice: 19258.9296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Η08Χ',
        healthProvider: 'Hospital C',
        minPrice: 517.969970703125,
        avgPrice: 9848.460538494017,
        maxPrice: 25578.8203125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 18,
      },
      {
        drg: 'Η23Μ',
        healthProvider: 'Hospital A',
        minPrice: 23230.650390625,
        avgPrice: 23230.650390625,
        maxPrice: 23230.650390625,
        minHospitalizationDays: 12,
        avgHospitalizationDays: 12,
        maxHospitalizationDays: 12,
      },
      {
        drg: 'Η23Χ',
        healthProvider: 'Hospital A',
        minPrice: 7313.2998046875,
        avgPrice: 13946.706380208334,
        maxPrice: 19845.189453125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Η23Χ',
        healthProvider: 'Hospital C',
        minPrice: 6207.08984375,
        avgPrice: 7255.759765625,
        maxPrice: 8304.4296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Η41Χ',
        healthProvider: 'Hospital B',
        minPrice: 29689.75,
        avgPrice: 32031.4296875,
        maxPrice: 34373.109375,
        minHospitalizationDays: 15,
        avgHospitalizationDays: 18,
        maxHospitalizationDays: 22,
      },
      {
        drg: 'Η41Χ',
        healthProvider: 'Hospital C',
        minPrice: 7721.7001953125,
        avgPrice: 8497.72021484375,
        maxPrice: 9273.740234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Η42Μ',
        healthProvider: 'Hospital A',
        minPrice: 22904.8203125,
        avgPrice: 22904.8203125,
        maxPrice: 22904.8203125,
        minHospitalizationDays: 10,
        avgHospitalizationDays: 10,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Η42Χ',
        healthProvider: 'Hospital B',
        minPrice: 6260.39013671875,
        avgPrice: 6260.39013671875,
        maxPrice: 6260.39013671875,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Η42Χ',
        healthProvider: 'Hospital C',
        minPrice: 4306.16015625,
        avgPrice: 6874.96484375,
        maxPrice: 9443.76953125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Η43Χ',
        healthProvider: 'Hospital C',
        minPrice: 8594.150390625,
        avgPrice: 15843.8203125,
        maxPrice: 23093.490234375,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Θ02Χ',
        healthProvider: 'Hospital C',
        minPrice: 12559.900390625,
        avgPrice: 12817.853515625,
        maxPrice: 13241.900390625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Θ05Χ',
        healthProvider: 'Hospital C',
        minPrice: 10019.7099609375,
        avgPrice: 11321.8349609375,
        maxPrice: 12941.8095703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Θ06Μ',
        healthProvider: 'Hospital B',
        minPrice: 11219.3701171875,
        avgPrice: 11219.3701171875,
        maxPrice: 11219.3701171875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Θ06Χ',
        healthProvider: 'Hospital A',
        minPrice: 4774.14013671875,
        avgPrice: 5563.0950927734375,
        maxPrice: 6609.64990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Θ06Χ',
        healthProvider: 'Hospital B',
        minPrice: 5953.31005859375,
        avgPrice: 9069.975640190973,
        maxPrice: 10546.150390625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Θ06Χ',
        healthProvider: 'Hospital C',
        minPrice: 8716.3798828125,
        avgPrice: 11425.016544117647,
        maxPrice: 20320.3203125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Θ20Χ',
        healthProvider: 'Hospital B',
        minPrice: 2559.219970703125,
        avgPrice: 2633.2099609375,
        maxPrice: 2707.199951171875,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Θ22Χ',
        healthProvider: 'Hospital A',
        minPrice: 7756.1201171875,
        avgPrice: 7756.1201171875,
        maxPrice: 7756.1201171875,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Θ22Χ',
        healthProvider: 'Hospital C',
        minPrice: 2104.860107421875,
        avgPrice: 2740.9366861979165,
        maxPrice: 3118.39990234375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ01Χ',
        healthProvider: 'Hospital B',
        minPrice: 32189.0703125,
        avgPrice: 32189.0703125,
        maxPrice: 32189.0703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ04Χ',
        healthProvider: 'Hospital C',
        minPrice: 32885.30078125,
        avgPrice: 46335.599609375,
        maxPrice: 67831.5234375,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 10,
        maxHospitalizationDays: 19,
      },
      {
        drg: 'Κ06Χ',
        healthProvider: 'Hospital A',
        minPrice: 25380.220703125,
        avgPrice: 25380.220703125,
        maxPrice: 25380.220703125,
        minHospitalizationDays: 9,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Κ06Χ',
        healthProvider: 'Hospital C',
        minPrice: 29755.009765625,
        avgPrice: 33240.6298828125,
        maxPrice: 36726.25,
        minHospitalizationDays: 8,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Κ10Χ',
        healthProvider: 'Hospital A',
        minPrice: 8846.419921875,
        avgPrice: 8846.419921875,
        maxPrice: 8846.419921875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ10Χ',
        healthProvider: 'Hospital C',
        minPrice: 5041.8203125,
        avgPrice: 18934.43289620536,
        maxPrice: 37153.58984375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Κ12Χ',
        healthProvider: 'Hospital C',
        minPrice: 16877.83984375,
        avgPrice: 16877.83984375,
        maxPrice: 16877.83984375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Κ14Χ',
        healthProvider: 'Hospital C',
        minPrice: 6776.080078125,
        avgPrice: 8436.4365234375,
        maxPrice: 11096.26953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ15Χ',
        healthProvider: 'Hospital A',
        minPrice: 5568.10009765625,
        avgPrice: 5761.78515625,
        maxPrice: 5955.47021484375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ15Χ',
        healthProvider: 'Hospital B',
        minPrice: 11451.4697265625,
        avgPrice: 14136.150065104166,
        maxPrice: 15532.6201171875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ15Χ',
        healthProvider: 'Hospital C',
        minPrice: 8659.740234375,
        avgPrice: 12293.895786830357,
        maxPrice: 17903.080078125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ20Α',
        healthProvider: 'Hospital A',
        minPrice: 2594.449951171875,
        avgPrice: 4165.502162388393,
        maxPrice: 6267.2900390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ20Α',
        healthProvider: 'Hospital C',
        minPrice: 296.8900146484375,
        avgPrice: 6320.093177208533,
        maxPrice: 9301.3203125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ22Α',
        healthProvider: 'Hospital A',
        minPrice: 25807.69921875,
        avgPrice: 25807.69921875,
        maxPrice: 25807.69921875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Κ31Χ',
        healthProvider: 'Hospital C',
        minPrice: 5954.669921875,
        avgPrice: 8095.77978515625,
        maxPrice: 10236.8896484375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Κ32Α',
        healthProvider: 'Hospital A',
        minPrice: 1109.8499755859375,
        avgPrice: 1760.3955485026042,
        maxPrice: 2738.02001953125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ32Α',
        healthProvider: 'Hospital B',
        minPrice: 2050.72998046875,
        avgPrice: 4989.106689453125,
        maxPrice: 9608.4501953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ32Α',
        healthProvider: 'Hospital C',
        minPrice: 2308.080078125,
        avgPrice: 3579.972957835478,
        maxPrice: 6028.18017578125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ32Χ',
        healthProvider: 'Hospital A',
        minPrice: 2562.679931640625,
        avgPrice: 3293.6200358072915,
        maxPrice: 4386.7001953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Κ32Χ',
        healthProvider: 'Hospital C',
        minPrice: 4595.27001953125,
        avgPrice: 6627.6732421875,
        maxPrice: 8908.08984375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ35Χ',
        healthProvider: 'Hospital B',
        minPrice: 3185.469970703125,
        avgPrice: 3185.469970703125,
        maxPrice: 3185.469970703125,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ35Χ',
        healthProvider: 'Hospital C',
        minPrice: 2034.72998046875,
        avgPrice: 2034.72998046875,
        maxPrice: 2034.72998046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ37Χ',
        healthProvider: 'Hospital A',
        minPrice: 2077.81005859375,
        avgPrice: 2077.81005859375,
        maxPrice: 2077.81005859375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ37Χ',
        healthProvider: 'Hospital B',
        minPrice: 3494.52001953125,
        avgPrice: 3494.52001953125,
        maxPrice: 3494.52001953125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Κ37Χ',
        healthProvider: 'Hospital C',
        minPrice: 3593.580078125,
        avgPrice: 3593.580078125,
        maxPrice: 3593.580078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ42Χ',
        healthProvider: 'Hospital B',
        minPrice: 4910.93994140625,
        avgPrice: 5777.215087890625,
        maxPrice: 6643.490234375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ42Χ',
        healthProvider: 'Hospital C',
        minPrice: 3113.3701171875,
        avgPrice: 10627.599975585938,
        maxPrice: 23639.76953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Κ43Χ',
        healthProvider: 'Hospital B',
        minPrice: 3746.780029296875,
        avgPrice: 3746.780029296875,
        maxPrice: 3746.780029296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ43Χ',
        healthProvider: 'Hospital C',
        minPrice: 2840.719970703125,
        avgPrice: 6458.015729631697,
        maxPrice: 8312.0498046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Κ44Α',
        healthProvider: 'Hospital A',
        minPrice: 1811.030029296875,
        avgPrice: 2611.9774780273438,
        maxPrice: 3508.179931640625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ44Α',
        healthProvider: 'Hospital B',
        minPrice: 721.5,
        avgPrice: 2362.660007052951,
        maxPrice: 7258.35009765625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Κ44Α',
        healthProvider: 'Hospital C',
        minPrice: 532.5700073242188,
        avgPrice: 3757.570573594835,
        maxPrice: 6501.6201171875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Κ45ΜΒ',
        healthProvider: 'Hospital A',
        minPrice: 17134.4609375,
        avgPrice: 17134.4609375,
        maxPrice: 17134.4609375,
        minHospitalizationDays: 10,
        avgHospitalizationDays: 10,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Κ45Χ',
        healthProvider: 'Hospital C',
        minPrice: 3294.929931640625,
        avgPrice: 3294.929931640625,
        maxPrice: 3294.929931640625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ46Χ',
        healthProvider: 'Hospital A',
        minPrice: 1046.6199951171875,
        avgPrice: 3089.6966552734375,
        maxPrice: 6981.22998046875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Κ46Χ',
        healthProvider: 'Hospital B',
        minPrice: 767.010009765625,
        avgPrice: 1137.1516927083333,
        maxPrice: 1569.75,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ46Χ',
        healthProvider: 'Hospital C',
        minPrice: 1709.719970703125,
        avgPrice: 4994.640024820964,
        maxPrice: 11054.8603515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Κ48Α',
        healthProvider: 'Hospital A',
        minPrice: 5306.02001953125,
        avgPrice: 5306.02001953125,
        maxPrice: 5306.02001953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ48Α',
        healthProvider: 'Hospital C',
        minPrice: 6922.2900390625,
        avgPrice: 7967.89501953125,
        maxPrice: 9013.5,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ49ΜΑ',
        healthProvider: 'Hospital A',
        minPrice: 11194.6298828125,
        avgPrice: 11194.6298828125,
        maxPrice: 11194.6298828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ49ΜΑ',
        healthProvider: 'Hospital B',
        minPrice: 19862.80078125,
        avgPrice: 22929.270833333332,
        maxPrice: 28030.9609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ49ΜΑ',
        healthProvider: 'Hospital C',
        minPrice: 14060.830078125,
        avgPrice: 18855.555013020832,
        maxPrice: 22703.98046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Κ49ΜΒ',
        healthProvider: 'Hospital B',
        minPrice: 7520.7099609375,
        avgPrice: 14879.4421875,
        maxPrice: 22760.720703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ49ΜΒ',
        healthProvider: 'Hospital C',
        minPrice: 11003.7802734375,
        avgPrice: 15206.43017578125,
        maxPrice: 19409.080078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Κ68Α',
        healthProvider: 'Hospital C',
        minPrice: 83223.0390625,
        avgPrice: 83223.0390625,
        maxPrice: 83223.0390625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Λ01Χ',
        healthProvider: 'Hospital B',
        minPrice: 6351.7998046875,
        avgPrice: 9399.990804036459,
        maxPrice: 10453.2998046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Λ03Χ',
        healthProvider: 'Hospital B',
        minPrice: 3034.889892578125,
        avgPrice: 3034.889892578125,
        maxPrice: 3034.889892578125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Λ26Α',
        healthProvider: 'Hospital B',
        minPrice: 790.9199829101562,
        avgPrice: 1425.1260131835938,
        maxPrice: 2310.330078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ03Χ',
        healthProvider: 'Hospital A',
        minPrice: 6245.52978515625,
        avgPrice: 9689.077392578125,
        maxPrice: 12419.5703125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ03Χ',
        healthProvider: 'Hospital C',
        minPrice: 10828.1904296875,
        avgPrice: 14093.3916015625,
        maxPrice: 15756.3896484375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Μ04Χ',
        healthProvider: 'Hospital A',
        minPrice: 6679.06005859375,
        avgPrice: 7799.005859375,
        maxPrice: 9125.990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ04Χ',
        healthProvider: 'Hospital C',
        minPrice: 7431.009765625,
        avgPrice: 13218.87998046875,
        maxPrice: 16295.26953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Μ08Χ',
        healthProvider: 'Hospital C',
        minPrice: 11073.25,
        avgPrice: 12779.486653645834,
        maxPrice: 14108.3203125,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Μ09Χ',
        healthProvider: 'Hospital A',
        minPrice: 7672.330078125,
        avgPrice: 16309.148716517857,
        maxPrice: 32417.3203125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Μ09Χ',
        healthProvider: 'Hospital C',
        minPrice: 16981.4296875,
        avgPrice: 30763.650065104168,
        maxPrice: 43653,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Μ09ΧΑ',
        healthProvider: 'Hospital A',
        minPrice: 16625.390625,
        avgPrice: 16625.390625,
        maxPrice: 16625.390625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ09ΧΑ',
        healthProvider: 'Hospital C',
        minPrice: 42157.6015625,
        avgPrice: 42157.6015625,
        maxPrice: 42157.6015625,
        minHospitalizationDays: 16,
        avgHospitalizationDays: 16,
        maxHospitalizationDays: 16,
      },
      {
        drg: 'Μ09ΧΒ',
        healthProvider: 'Hospital C',
        minPrice: 11479.25,
        avgPrice: 23984.48046875,
        maxPrice: 36489.7109375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 8,
        maxHospitalizationDays: 16,
      },
      {
        drg: 'Μ10ΧΔ',
        healthProvider: 'Hospital A',
        minPrice: 1222.699951171875,
        avgPrice: 12136.772644042969,
        maxPrice: 41393.890625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Μ10ΧΔ',
        healthProvider: 'Hospital B',
        minPrice: 11337.7197265625,
        avgPrice: 11337.7197265625,
        maxPrice: 11337.7197265625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ10ΧΔ',
        healthProvider: 'Hospital C',
        minPrice: 4389.9599609375,
        avgPrice: 11880.608317057291,
        maxPrice: 27939.01953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Μ12Χ',
        healthProvider: 'Hospital C',
        minPrice: 9299.669921875,
        avgPrice: 9299.669921875,
        maxPrice: 9299.669921875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Μ13Χ',
        healthProvider: 'Hospital C',
        minPrice: 247.91000366210938,
        avgPrice: 8265.35178120931,
        maxPrice: 12746.060546875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ16Α',
        healthProvider: 'Hospital C',
        minPrice: 5353.580078125,
        avgPrice: 9942.4599609375,
        maxPrice: 12328.51953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ18Α',
        healthProvider: 'Hospital B',
        minPrice: 12467.3603515625,
        avgPrice: 12467.3603515625,
        maxPrice: 12467.3603515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ18Α',
        healthProvider: 'Hospital C',
        minPrice: 9114.1103515625,
        avgPrice: 9114.1103515625,
        maxPrice: 9114.1103515625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ19Χ',
        healthProvider: 'Hospital A',
        minPrice: 1548.72998046875,
        avgPrice: 1548.72998046875,
        maxPrice: 1548.72998046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ19Χ',
        healthProvider: 'Hospital B',
        minPrice: 4237.8798828125,
        avgPrice: 4237.8798828125,
        maxPrice: 4237.8798828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ19Χ',
        healthProvider: 'Hospital C',
        minPrice: 2947.1298828125,
        avgPrice: 9559.736653645834,
        maxPrice: 18057.66015625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ20Α',
        healthProvider: 'Hospital A',
        minPrice: 4056.6298828125,
        avgPrice: 4624.494873046875,
        maxPrice: 5192.35986328125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ20Α',
        healthProvider: 'Hospital B',
        minPrice: 5850.3798828125,
        avgPrice: 5850.3798828125,
        maxPrice: 5850.3798828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ20Α',
        healthProvider: 'Hospital C',
        minPrice: 3618.1298828125,
        avgPrice: 4248.18505859375,
        maxPrice: 4878.240234375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ21Α',
        healthProvider: 'Hospital C',
        minPrice: 4479.5498046875,
        avgPrice: 4651.1099853515625,
        maxPrice: 4889.330078125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Μ22Α',
        healthProvider: 'Hospital A',
        minPrice: 1959.0799560546875,
        avgPrice: 5275.4125286599865,
        maxPrice: 9602.01953125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ22Α',
        healthProvider: 'Hospital B',
        minPrice: 4027.340087890625,
        avgPrice: 10674.125711192255,
        maxPrice: 16631.169921875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Μ22Α',
        healthProvider: 'Hospital C',
        minPrice: 4373.2998046875,
        avgPrice: 9844.194905598959,
        maxPrice: 15428.76953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Μ23Α',
        healthProvider: 'Hospital A',
        minPrice: 1502.9599609375,
        avgPrice: 1602.0700073242188,
        maxPrice: 1701.1800537109375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ23Α',
        healthProvider: 'Hospital B',
        minPrice: 2979.219970703125,
        avgPrice: 3155.364990234375,
        maxPrice: 3331.510009765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ23Α',
        healthProvider: 'Hospital C',
        minPrice: 1636.4300537109375,
        avgPrice: 3863.591543344351,
        maxPrice: 6153.97021484375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ24Α',
        healthProvider: 'Hospital C',
        minPrice: 305.04998779296875,
        avgPrice: 6158.66635301378,
        maxPrice: 11155.759765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Μ25Χ',
        healthProvider: 'Hospital B',
        minPrice: 9097.7099609375,
        avgPrice: 11332.18994140625,
        maxPrice: 13566.669921875,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ25Χ',
        healthProvider: 'Hospital C',
        minPrice: 7456.2900390625,
        avgPrice: 7456.2900390625,
        maxPrice: 7456.2900390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Μ27Χ',
        healthProvider: 'Hospital C',
        minPrice: 7512.52978515625,
        avgPrice: 7512.52978515625,
        maxPrice: 7512.52978515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ28Χ',
        healthProvider: 'Hospital C',
        minPrice: 5927.22998046875,
        avgPrice: 5927.22998046875,
        maxPrice: 5927.22998046875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Μ30Α',
        healthProvider: 'Hospital A',
        minPrice: 1413.5,
        avgPrice: 1957.4700520833333,
        maxPrice: 2362.050048828125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ30Α',
        healthProvider: 'Hospital C',
        minPrice: 303.7799987792969,
        avgPrice: 5870.106643676758,
        maxPrice: 9100.919921875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ64Χ',
        healthProvider: 'Hospital B',
        minPrice: 17491.33984375,
        avgPrice: 17491.33984375,
        maxPrice: 17491.33984375,
        minHospitalizationDays: 15,
        avgHospitalizationDays: 15,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Μ65Χ',
        healthProvider: 'Hospital C',
        minPrice: 8199.2998046875,
        avgPrice: 8199.2998046875,
        maxPrice: 8199.2998046875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Μ66Χ',
        healthProvider: 'Hospital C',
        minPrice: 6477.41015625,
        avgPrice: 6477.41015625,
        maxPrice: 6477.41015625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ68Α',
        healthProvider: 'Hospital B',
        minPrice: 1614.239990234375,
        avgPrice: 1614.239990234375,
        maxPrice: 1614.239990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ68Χ',
        healthProvider: 'Hospital C',
        minPrice: 1365.7900390625,
        avgPrice: 3288.7874450683594,
        maxPrice: 5858.7099609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ69Χ',
        healthProvider: 'Hospital C',
        minPrice: 2980.10009765625,
        avgPrice: 5571.2419921875,
        maxPrice: 8935.4697265625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Μ71Χ',
        healthProvider: 'Hospital A',
        minPrice: 721.8599853515625,
        avgPrice: 1511.0050048828125,
        maxPrice: 2096.860107421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Μ71Χ',
        healthProvider: 'Hospital B',
        minPrice: 424.239990234375,
        avgPrice: 1532.0824890136719,
        maxPrice: 2487.510009765625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ71Χ',
        healthProvider: 'Hospital C',
        minPrice: 158.11000061035156,
        avgPrice: 1692.2771628243584,
        maxPrice: 3576.60009765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ74Α',
        healthProvider: 'Hospital A',
        minPrice: 7804.10986328125,
        avgPrice: 7804.10986328125,
        maxPrice: 7804.10986328125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ74Α',
        healthProvider: 'Hospital B',
        minPrice: 2796.419921875,
        avgPrice: 3750.5736194957385,
        maxPrice: 7524.1298828125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ74Α',
        healthProvider: 'Hospital C',
        minPrice: 2520.760009765625,
        avgPrice: 4330.026692708333,
        maxPrice: 6855.0400390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Μ75Χ',
        healthProvider: 'Hospital A',
        minPrice: 2406.639892578125,
        avgPrice: 2406.639892578125,
        maxPrice: 2406.639892578125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Μ75Χ',
        healthProvider: 'Hospital B',
        minPrice: 3288.27001953125,
        avgPrice: 5928.18994140625,
        maxPrice: 12003.099609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Μ75Χ',
        healthProvider: 'Hospital C',
        minPrice: 186.8699951171875,
        avgPrice: 5676.967956542969,
        maxPrice: 7930.18994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Μ76Μ',
        healthProvider: 'Hospital B',
        minPrice: 11920.7998046875,
        avgPrice: 11920.7998046875,
        maxPrice: 11920.7998046875,
        minHospitalizationDays: 11,
        avgHospitalizationDays: 11,
        maxHospitalizationDays: 11,
      },
      {
        drg: 'Μ76Χ',
        healthProvider: 'Hospital B',
        minPrice: 3562.97998046875,
        avgPrice: 7641.276529947917,
        maxPrice: 10994.1396484375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Μ79Χ',
        healthProvider: 'Hospital C',
        minPrice: 24872.119140625,
        avgPrice: 24872.119140625,
        maxPrice: 24872.119140625,
        minHospitalizationDays: 29,
        avgHospitalizationDays: 29,
        maxHospitalizationDays: 29,
      },
      {
        drg: 'Ν02Χ',
        healthProvider: 'Hospital B',
        minPrice: 17468.5,
        avgPrice: 17468.5,
        maxPrice: 17468.5,
        minHospitalizationDays: 8,
        avgHospitalizationDays: 8,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Ν02Χ',
        healthProvider: 'Hospital C',
        minPrice: 1460.8399658203125,
        avgPrice: 22361.215140206474,
        maxPrice: 45089.80078125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 14,
      },
      {
        drg: 'Ν03Χ',
        healthProvider: 'Hospital C',
        minPrice: 23473.400390625,
        avgPrice: 27941.01220703125,
        maxPrice: 32481.01953125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Ν05Α',
        healthProvider: 'Hospital A',
        minPrice: 3728.330078125,
        avgPrice: 3728.330078125,
        maxPrice: 3728.330078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ν05Α',
        healthProvider: 'Hospital C',
        minPrice: 2712.530029296875,
        avgPrice: 3329.644287109375,
        maxPrice: 3742.530029296875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ν07Χ',
        healthProvider: 'Hospital A',
        minPrice: 30248.51953125,
        avgPrice: 38564.935546875,
        maxPrice: 46881.3515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ν07Χ',
        healthProvider: 'Hospital C',
        minPrice: 7010.81005859375,
        avgPrice: 10008.239990234375,
        maxPrice: 13005.669921875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ν21Χ',
        healthProvider: 'Hospital A',
        minPrice: 4349.43017578125,
        avgPrice: 4349.43017578125,
        maxPrice: 4349.43017578125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν26Χ',
        healthProvider: 'Hospital C',
        minPrice: 6367.1201171875,
        avgPrice: 6663.545166015625,
        maxPrice: 6959.97021484375,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ν28Χ',
        healthProvider: 'Hospital B',
        minPrice: 1950.75,
        avgPrice: 2738.35498046875,
        maxPrice: 3525.9599609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ν28Χ',
        healthProvider: 'Hospital C',
        minPrice: 2943.8798828125,
        avgPrice: 2943.8798828125,
        maxPrice: 2943.8798828125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν30Α',
        healthProvider: 'Hospital C',
        minPrice: 4742.330078125,
        avgPrice: 5073.31494140625,
        maxPrice: 5404.2998046875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν30Χ',
        healthProvider: 'Hospital A',
        minPrice: 5615.52978515625,
        avgPrice: 5615.52978515625,
        maxPrice: 5615.52978515625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ν30Χ',
        healthProvider: 'Hospital B',
        minPrice: 8691.509765625,
        avgPrice: 10392.220052083334,
        maxPrice: 11316.8203125,
        minHospitalizationDays: 6,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Ν30Χ',
        healthProvider: 'Hospital C',
        minPrice: 5122.89990234375,
        avgPrice: 12654.49365234375,
        maxPrice: 37382.1015625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 14,
      },
      {
        drg: 'Ν31Χ',
        healthProvider: 'Hospital B',
        minPrice: 2587.22998046875,
        avgPrice: 2587.22998046875,
        maxPrice: 2587.22998046875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ν31Χ',
        healthProvider: 'Hospital C',
        minPrice: 217.6199951171875,
        avgPrice: 5135.0398356119795,
        maxPrice: 9504.6396484375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Ν33Α',
        healthProvider: 'Hospital B',
        minPrice: 8178.2900390625,
        avgPrice: 8178.2900390625,
        maxPrice: 8178.2900390625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν33Α',
        healthProvider: 'Hospital C',
        minPrice: 7806.18017578125,
        avgPrice: 7806.18017578125,
        maxPrice: 7806.18017578125,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ν35Α',
        healthProvider: 'Hospital B',
        minPrice: 1534,
        avgPrice: 1839.2575073242188,
        maxPrice: 2262.800048828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν36Χ',
        healthProvider: 'Hospital A',
        minPrice: 5101.02001953125,
        avgPrice: 6868.295166015625,
        maxPrice: 8635.5703125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Ν36Χ',
        healthProvider: 'Hospital B',
        minPrice: 2563.830078125,
        avgPrice: 6788.35498046875,
        maxPrice: 11012.8798828125,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 12,
      },
      {
        drg: 'Ν37Α',
        healthProvider: 'Hospital B',
        minPrice: 266.5400085449219,
        avgPrice: 4177.729922824436,
        maxPrice: 16551.939453125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 14,
      },
      {
        drg: 'Ν37Α',
        healthProvider: 'Hospital C',
        minPrice: 113.31999969482422,
        avgPrice: 3222.914960861206,
        maxPrice: 4397.3798828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν38Χ',
        healthProvider: 'Hospital C',
        minPrice: 42259.078125,
        avgPrice: 42259.078125,
        maxPrice: 42259.078125,
        minHospitalizationDays: 27,
        avgHospitalizationDays: 27,
        maxHospitalizationDays: 27,
      },
      {
        drg: 'Ν40Α',
        healthProvider: 'Hospital B',
        minPrice: 511.4100036621094,
        avgPrice: 1127.9866570366753,
        maxPrice: 2386.580078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ν40Α',
        healthProvider: 'Hospital C',
        minPrice: 2844.18994140625,
        avgPrice: 2844.18994140625,
        maxPrice: 2844.18994140625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ν41Χ',
        healthProvider: 'Hospital A',
        minPrice: 1811.4599609375,
        avgPrice: 1811.4599609375,
        maxPrice: 1811.4599609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ν41Χ',
        healthProvider: 'Hospital B',
        minPrice: 662.3099975585938,
        avgPrice: 2277.4099862234934,
        maxPrice: 6229.7998046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Ν41Χ',
        healthProvider: 'Hospital C',
        minPrice: 4684.68017578125,
        avgPrice: 4941.945068359375,
        maxPrice: 5199.2099609375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ξ20ΧΒ',
        healthProvider: 'Hospital B',
        minPrice: 1958.1300048828125,
        avgPrice: 1958.1300048828125,
        maxPrice: 1958.1300048828125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ξ21Χ',
        healthProvider: 'Hospital B',
        minPrice: 639.1599731445312,
        avgPrice: 4640.109878540039,
        maxPrice: 10919.5498046875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Ξ21Χ',
        healthProvider: 'Hospital C',
        minPrice: 398.2699890136719,
        avgPrice: 1915.6466420491536,
        maxPrice: 3221.4599609375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ξ22Α',
        healthProvider: 'Hospital B',
        minPrice: 3572.179931640625,
        avgPrice: 3572.179931640625,
        maxPrice: 3572.179931640625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ο10Α',
        healthProvider: 'Hospital B',
        minPrice: 3876.070068359375,
        avgPrice: 5170.733479817708,
        maxPrice: 5928.06005859375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ο11Α',
        healthProvider: 'Hospital C',
        minPrice: 3569.969970703125,
        avgPrice: 4710.7374267578125,
        maxPrice: 6316.43994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ο16Α',
        healthProvider: 'Hospital A',
        minPrice: 1245.1500244140625,
        avgPrice: 1285.030029296875,
        maxPrice: 1324.9100341796875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ο16Α',
        healthProvider: 'Hospital C',
        minPrice: 3184.820068359375,
        avgPrice: 4258.62296070772,
        maxPrice: 7215.919921875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ο23Α',
        healthProvider: 'Hospital B',
        minPrice: 2704.830078125,
        avgPrice: 3534.7233072916665,
        maxPrice: 4271.56982421875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ο61Χ',
        healthProvider: 'Hospital C',
        minPrice: 2575.60009765625,
        avgPrice: 2575.60009765625,
        maxPrice: 2575.60009765625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π01Χ',
        healthProvider: 'Hospital B',
        minPrice: 19014.900390625,
        avgPrice: 19014.900390625,
        maxPrice: 19014.900390625,
        minHospitalizationDays: 6,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π01Χ',
        healthProvider: 'Hospital C',
        minPrice: 27955.150390625,
        avgPrice: 27955.150390625,
        maxPrice: 27955.150390625,
        minHospitalizationDays: 6,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π02Χ',
        healthProvider: 'Hospital A',
        minPrice: 17769.189453125,
        avgPrice: 24598.359375,
        maxPrice: 36523.19921875,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 14,
        maxHospitalizationDays: 23,
      },
      {
        drg: 'Π02Χ',
        healthProvider: 'Hospital B',
        minPrice: 112279.296875,
        avgPrice: 112279.296875,
        maxPrice: 112279.296875,
        minHospitalizationDays: 46,
        avgHospitalizationDays: 46,
        maxHospitalizationDays: 46,
      },
      {
        drg: 'Π02Χ',
        healthProvider: 'Hospital C',
        minPrice: 15369.73046875,
        avgPrice: 24453.72677951389,
        maxPrice: 34350.48046875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 11,
      },
      {
        drg: 'Π03ΧΑ',
        healthProvider: 'Hospital C',
        minPrice: 36140.87890625,
        avgPrice: 36140.87890625,
        maxPrice: 36140.87890625,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Π03ΧΒ',
        healthProvider: 'Hospital A',
        minPrice: 5424.2998046875,
        avgPrice: 9217.722981770834,
        maxPrice: 11268.5595703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π03ΧΒ',
        healthProvider: 'Hospital B',
        minPrice: 11373.4599609375,
        avgPrice: 11828.8701171875,
        maxPrice: 12284.2802734375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Π04Χ',
        healthProvider: 'Hospital B',
        minPrice: 9621.23046875,
        avgPrice: 10204,
        maxPrice: 10786.76953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π05Χ',
        healthProvider: 'Hospital B',
        minPrice: 10252.8603515625,
        avgPrice: 10252.8603515625,
        maxPrice: 10252.8603515625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Π05Χ',
        healthProvider: 'Hospital C',
        minPrice: 9298.4501953125,
        avgPrice: 11528.600260416666,
        maxPrice: 12969.0703125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π07Μ',
        healthProvider: 'Hospital A',
        minPrice: 6874.18017578125,
        avgPrice: 8311.665283203125,
        maxPrice: 9749.150390625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π07Μ',
        healthProvider: 'Hospital B',
        minPrice: 4010.72998046875,
        avgPrice: 7743.01396484375,
        maxPrice: 11328.919921875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π07Χ',
        healthProvider: 'Hospital A',
        minPrice: 6708.5498046875,
        avgPrice: 7460.6298828125,
        maxPrice: 8212.7099609375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π07Χ',
        healthProvider: 'Hospital B',
        minPrice: 2063.639892578125,
        avgPrice: 8266.962330537684,
        maxPrice: 11378.3798828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π07Χ',
        healthProvider: 'Hospital C',
        minPrice: 1963.050048828125,
        avgPrice: 9287.733430989583,
        maxPrice: 17991.4609375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Π10Χ',
        healthProvider: 'Hospital A',
        minPrice: 2458.8798828125,
        avgPrice: 5848.594665527344,
        maxPrice: 15292.66015625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Π10Χ',
        healthProvider: 'Hospital B',
        minPrice: 860.2000122070312,
        avgPrice: 5394.238807678223,
        maxPrice: 13396.0400390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π10Χ',
        healthProvider: 'Hospital C',
        minPrice: 2851.72998046875,
        avgPrice: 9001.81418998125,
        maxPrice: 15987.58473,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π11Α',
        healthProvider: 'Hospital A',
        minPrice: 2504.1201171875,
        avgPrice: 3228.3654563210225,
        maxPrice: 4833.06982421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Π11Α',
        healthProvider: 'Hospital B',
        minPrice: 4784.85986328125,
        avgPrice: 22162.590087890625,
        maxPrice: 39540.3203125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 17,
        maxHospitalizationDays: 34,
      },
      {
        drg: 'Π11Α',
        healthProvider: 'Hospital C',
        minPrice: 1793,
        avgPrice: 5512.215361357872,
        maxPrice: 21175.330078125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Π12Χ',
        healthProvider: 'Hospital B',
        minPrice: 15920.169921875,
        avgPrice: 15920.169921875,
        maxPrice: 15920.169921875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π12Χ',
        healthProvider: 'Hospital C',
        minPrice: 16998.810546875,
        avgPrice: 16998.810546875,
        maxPrice: 16998.810546875,
        minHospitalizationDays: 6,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π26Α',
        healthProvider: 'Hospital B',
        minPrice: 1466.780029296875,
        avgPrice: 2605.276025390625,
        maxPrice: 3841.659912109375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Π27Α',
        healthProvider: 'Hospital A',
        minPrice: 3190.760009765625,
        avgPrice: 3848.8499755859375,
        maxPrice: 4506.93994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π27Α',
        healthProvider: 'Hospital C',
        minPrice: 2232,
        avgPrice: 2232,
        maxPrice: 2232,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π27Χ',
        healthProvider: 'Hospital A',
        minPrice: 1241.5699462890625,
        avgPrice: 1722.2133382161458,
        maxPrice: 2492.610107421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Π27Χ',
        healthProvider: 'Hospital B',
        minPrice: 3104.570068359375,
        avgPrice: 3104.570068359375,
        maxPrice: 3104.570068359375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π27Χ',
        healthProvider: 'Hospital C',
        minPrice: 29111.259765625,
        avgPrice: 29111.259765625,
        maxPrice: 29111.259765625,
        minHospitalizationDays: 20,
        avgHospitalizationDays: 20,
        maxHospitalizationDays: 20,
      },
      {
        drg: 'Π28Α',
        healthProvider: 'Hospital A',
        minPrice: 1841.0999755859375,
        avgPrice: 4192.577484130859,
        maxPrice: 7552.85986328125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Π28Χ',
        healthProvider: 'Hospital C',
        minPrice: 9448.9697265625,
        avgPrice: 9448.9697265625,
        maxPrice: 9448.9697265625,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Π40Μ',
        healthProvider: 'Hospital A',
        minPrice: 14009.3896484375,
        avgPrice: 18892.52978515625,
        maxPrice: 23775.669921875,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Π40Χ',
        healthProvider: 'Hospital A',
        minPrice: 4872.56982421875,
        avgPrice: 4872.56982421875,
        maxPrice: 4872.56982421875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Π40Χ',
        healthProvider: 'Hospital B',
        minPrice: 5358.509765625,
        avgPrice: 6178.804931640625,
        maxPrice: 6999.10009765625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Π40Χ',
        healthProvider: 'Hospital C',
        minPrice: 14485.4296875,
        avgPrice: 17695.112955729168,
        maxPrice: 23688.619140625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Π41Χ',
        healthProvider: 'Hospital A',
        minPrice: 740.0700073242188,
        avgPrice: 1826.7699890136719,
        maxPrice: 2913.469970703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Π41Χ',
        healthProvider: 'Hospital B',
        minPrice: 1550.8699951171875,
        avgPrice: 5096.6732991536455,
        maxPrice: 8044.58984375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π41Χ',
        healthProvider: 'Hospital C',
        minPrice: 1350.6800537109375,
        avgPrice: 5583.68889702691,
        maxPrice: 11130.51953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Π43Α',
        healthProvider: 'Hospital B',
        minPrice: 7622.0400390625,
        avgPrice: 7622.0400390625,
        maxPrice: 7622.0400390625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π44Μ',
        healthProvider: 'Hospital A',
        minPrice: 16593.369140625,
        avgPrice: 16593.369140625,
        maxPrice: 16593.369140625,
        minHospitalizationDays: 9,
        avgHospitalizationDays: 9,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Π44Μ',
        healthProvider: 'Hospital C',
        minPrice: 79981.78125,
        avgPrice: 79981.78125,
        maxPrice: 79981.78125,
        minHospitalizationDays: 27,
        avgHospitalizationDays: 27,
        maxHospitalizationDays: 27,
      },
      {
        drg: 'Π44Χ',
        healthProvider: 'Hospital A',
        minPrice: 3040.72998046875,
        avgPrice: 6094.268017578125,
        maxPrice: 11378.650390625,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 14,
      },
      {
        drg: 'Π44Χ',
        healthProvider: 'Hospital B',
        minPrice: 984.969970703125,
        avgPrice: 6065.0734456380205,
        maxPrice: 15837.9404296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Π44Χ',
        healthProvider: 'Hospital C',
        minPrice: 775.8900146484375,
        avgPrice: 3831.795399058949,
        maxPrice: 4953.22998046875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Π45Μ',
        healthProvider: 'Hospital B',
        minPrice: 57939.98828125,
        avgPrice: 57939.98828125,
        maxPrice: 57939.98828125,
        minHospitalizationDays: 42,
        avgHospitalizationDays: 42,
        maxHospitalizationDays: 42,
      },
      {
        drg: 'Π45Χ',
        healthProvider: 'Hospital C',
        minPrice: 4662.33984375,
        avgPrice: 4662.33984375,
        maxPrice: 4662.33984375,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π46Α',
        healthProvider: 'Hospital A',
        minPrice: 2772.0400390625,
        avgPrice: 3200.2650146484375,
        maxPrice: 3628.489990234375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Π46Α',
        healthProvider: 'Hospital B',
        minPrice: 609.2999877929688,
        avgPrice: 1814.4809814453124,
        maxPrice: 3467.179931640625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π46Α',
        healthProvider: 'Hospital C',
        minPrice: 1273.1500244140625,
        avgPrice: 4440.8263827237215,
        maxPrice: 8329.080078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π47Χ',
        healthProvider: 'Hospital A',
        minPrice: 856.3200073242188,
        avgPrice: 2144.1733194986978,
        maxPrice: 3187.169921875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Π47Χ',
        healthProvider: 'Hospital B',
        minPrice: 0,
        avgPrice: 1934.1992886973694,
        maxPrice: 6501.27978515625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π47Χ',
        healthProvider: 'Hospital C',
        minPrice: 143.94000244140625,
        avgPrice: 3962.4771597726003,
        maxPrice: 8292.5703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Π50Χ',
        healthProvider: 'Hospital B',
        minPrice: 2077.35009765625,
        avgPrice: 4061.925048828125,
        maxPrice: 6046.5,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Π50Χ',
        healthProvider: 'Hospital C',
        minPrice: 3048.139892578125,
        avgPrice: 4503.014953613281,
        maxPrice: 7151.14013671875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ρ20Χ',
        healthProvider: 'Hospital A',
        minPrice: 20364.779296875,
        avgPrice: 37532.6240234375,
        maxPrice: 54700.46875,
        minHospitalizationDays: 13,
        avgHospitalizationDays: 17,
        maxHospitalizationDays: 21,
      },
      {
        drg: 'Ρ20Χ',
        healthProvider: 'Hospital B',
        minPrice: 3228.5,
        avgPrice: 4316.472595214844,
        maxPrice: 6219.740234375,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Ρ20Χ',
        healthProvider: 'Hospital C',
        minPrice: 6971.43017578125,
        avgPrice: 21511.43701171875,
        maxPrice: 36742.37109375,
        minHospitalizationDays: 4,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Ρ21Χ',
        healthProvider: 'Hospital A',
        minPrice: 8448.83984375,
        avgPrice: 8448.83984375,
        maxPrice: 8448.83984375,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Ρ21Χ',
        healthProvider: 'Hospital B',
        minPrice: 24278.4609375,
        avgPrice: 24278.4609375,
        maxPrice: 24278.4609375,
        minHospitalizationDays: 15,
        avgHospitalizationDays: 15,
        maxHospitalizationDays: 15,
      },
      {
        drg: 'Ρ22Χ',
        healthProvider: 'Hospital A',
        minPrice: 5901.1201171875,
        avgPrice: 8454.136881510416,
        maxPrice: 11866.6103515625,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 7,
      },
      {
        drg: 'Ρ22Χ',
        healthProvider: 'Hospital B',
        minPrice: 1216.1600341796875,
        avgPrice: 3721.6200256347656,
        maxPrice: 6570.81005859375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ρ22Χ',
        healthProvider: 'Hospital C',
        minPrice: 6141.6201171875,
        avgPrice: 8637.29814453125,
        maxPrice: 10972.490234375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Ρ23Α',
        healthProvider: 'Hospital A',
        minPrice: 3701.330078125,
        avgPrice: 3701.330078125,
        maxPrice: 3701.330078125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ρ23Α',
        healthProvider: 'Hospital B',
        minPrice: 403.260009765625,
        avgPrice: 2260.3731384277344,
        maxPrice: 4516.240234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Ρ23Α',
        healthProvider: 'Hospital C',
        minPrice: 970.0800170898438,
        avgPrice: 5076.294906616211,
        maxPrice: 10722.2197265625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Ρ24Χ',
        healthProvider: 'Hospital B',
        minPrice: 2695.39990234375,
        avgPrice: 2695.39990234375,
        maxPrice: 2695.39990234375,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ρ24Χ',
        healthProvider: 'Hospital C',
        minPrice: 600,
        avgPrice: 3322.711669921875,
        maxPrice: 7337.740234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Σ22Μ',
        healthProvider: 'Hospital B',
        minPrice: 3714.35009765625,
        avgPrice: 3714.35009765625,
        maxPrice: 3714.35009765625,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Σ22Χ',
        healthProvider: 'Hospital A',
        minPrice: 930.4600219726562,
        avgPrice: 1843.0695902506511,
        maxPrice: 18020.91015625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Σ22Χ',
        healthProvider: 'Hospital B',
        minPrice: 767.47998046875,
        avgPrice: 2707.280780498798,
        maxPrice: 6961.72021484375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Υ03Χ',
        healthProvider: 'Hospital A',
        minPrice: 8474.25,
        avgPrice: 9704.83984375,
        maxPrice: 10935.4296875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Υ03Χ',
        healthProvider: 'Hospital B',
        minPrice: 19836.369140625,
        avgPrice: 19836.369140625,
        maxPrice: 19836.369140625,
        minHospitalizationDays: 10,
        avgHospitalizationDays: 10,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Υ03Χ',
        healthProvider: 'Hospital C',
        minPrice: 14450.169921875,
        avgPrice: 17074.926171875,
        maxPrice: 21268.990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Υ04Χ',
        healthProvider: 'Hospital B',
        minPrice: 4524.68994140625,
        avgPrice: 6612.175048828125,
        maxPrice: 8699.66015625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Υ04Χ',
        healthProvider: 'Hospital C',
        minPrice: 14471.009765625,
        avgPrice: 14471.009765625,
        maxPrice: 14471.009765625,
        minHospitalizationDays: 3,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Υ07Χ',
        healthProvider: 'Hospital A',
        minPrice: 2355.77001953125,
        avgPrice: 2848.114990234375,
        maxPrice: 3340.4599609375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Υ07Χ',
        healthProvider: 'Hospital B',
        minPrice: 1239.010009765625,
        avgPrice: 4891.049865722656,
        maxPrice: 9823.9296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Υ07Χ',
        healthProvider: 'Hospital C',
        minPrice: 276.2699890136719,
        avgPrice: 6817.390457763672,
        maxPrice: 12554,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Υ08Χ',
        healthProvider: 'Hospital A',
        minPrice: 1560.18994140625,
        avgPrice: 1560.18994140625,
        maxPrice: 1560.18994140625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ08Χ',
        healthProvider: 'Hospital C',
        minPrice: 8640.9296875,
        avgPrice: 8640.9296875,
        maxPrice: 8640.9296875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Υ20Α',
        healthProvider: 'Hospital A',
        minPrice: 3319.590087890625,
        avgPrice: 3319.590087890625,
        maxPrice: 3319.590087890625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ20Α',
        healthProvider: 'Hospital B',
        minPrice: 5203.8798828125,
        avgPrice: 5203.8798828125,
        maxPrice: 5203.8798828125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Υ21Α',
        healthProvider: 'Hospital B',
        minPrice: 3693.010009765625,
        avgPrice: 4798.8299560546875,
        maxPrice: 5904.64990234375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Υ21Α',
        healthProvider: 'Hospital C',
        minPrice: 1385.6400146484375,
        avgPrice: 2871.602001953125,
        maxPrice: 4049.590087890625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ22Α',
        healthProvider: 'Hospital A',
        minPrice: 738.72998046875,
        avgPrice: 831.55177961077,
        maxPrice: 1062.47998046875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ22Χ',
        healthProvider: 'Hospital C',
        minPrice: 4169.830078125,
        avgPrice: 4169.830078125,
        maxPrice: 4169.830078125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ23Μ',
        healthProvider: 'Hospital A',
        minPrice: 10390.6201171875,
        avgPrice: 10390.6201171875,
        maxPrice: 10390.6201171875,
        minHospitalizationDays: 13,
        avgHospitalizationDays: 13,
        maxHospitalizationDays: 13,
      },
      {
        drg: 'Υ23Χ',
        healthProvider: 'Hospital A',
        minPrice: 2303.949951171875,
        avgPrice: 5987.952001953125,
        maxPrice: 10420.2802734375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 6,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Υ23Χ',
        healthProvider: 'Hospital B',
        minPrice: 2202.590087890625,
        avgPrice: 3937.3729073660716,
        maxPrice: 8980.4404296875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Υ23Χ',
        healthProvider: 'Hospital C',
        minPrice: 604.47998046875,
        avgPrice: 4677.622183663504,
        maxPrice: 9739.2802734375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Υ24Α',
        healthProvider: 'Hospital A',
        minPrice: 1309.969970703125,
        avgPrice: 4012.3109019886365,
        maxPrice: 6571.75,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Υ24Α',
        healthProvider: 'Hospital B',
        minPrice: 1020.0900268554688,
        avgPrice: 6955.654479980469,
        maxPrice: 10994.91015625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Υ24Α',
        healthProvider: 'Hospital C',
        minPrice: 167.13999938964844,
        avgPrice: 5198.2597397205445,
        maxPrice: 11825.66015625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Υ25Χ',
        healthProvider: 'Hospital B',
        minPrice: 354.3399963378906,
        avgPrice: 3163.7842952183314,
        maxPrice: 6635.1298828125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Υ25Χ',
        healthProvider: 'Hospital C',
        minPrice: 2185.2900390625,
        avgPrice: 2185.2900390625,
        maxPrice: 2185.2900390625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Υ26Α',
        healthProvider: 'Hospital A',
        minPrice: 1511.25,
        avgPrice: 1511.25,
        maxPrice: 1511.25,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ26Α',
        healthProvider: 'Hospital C',
        minPrice: 3696.39990234375,
        avgPrice: 3696.39990234375,
        maxPrice: 3696.39990234375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Υ27Χ',
        healthProvider: 'Hospital B',
        minPrice: 4184.0400390625,
        avgPrice: 4477.677490234375,
        maxPrice: 4585.27001953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Υ30Α',
        healthProvider: 'Hospital A',
        minPrice: 1233.8699951171875,
        avgPrice: 1754.7200317382812,
        maxPrice: 2275.570068359375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Φ25Χ',
        healthProvider: 'Hospital A',
        minPrice: 2135.860107421875,
        avgPrice: 2135.860107421875,
        maxPrice: 2135.860107421875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Φ25Χ',
        healthProvider: 'Hospital C',
        minPrice: 3637.820068359375,
        avgPrice: 3637.820068359375,
        maxPrice: 3637.820068359375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Φ60Μ',
        healthProvider: 'Hospital B',
        minPrice: 13534.6298828125,
        avgPrice: 13534.6298828125,
        maxPrice: 13534.6298828125,
        minHospitalizationDays: 16,
        avgHospitalizationDays: 16,
        maxHospitalizationDays: 16,
      },
      {
        drg: 'Φ61Α',
        healthProvider: 'Hospital B',
        minPrice: 1205.97998046875,
        avgPrice: 1602.5850219726562,
        maxPrice: 2051.860107421875,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Φ61Α',
        healthProvider: 'Hospital C',
        minPrice: 1920.6700439453125,
        avgPrice: 3673.3101196289062,
        maxPrice: 5425.9501953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Φ62Χ',
        healthProvider: 'Hospital B',
        minPrice: 712.9199829101562,
        avgPrice: 1328.3181873668325,
        maxPrice: 2046.27001953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Φ63Χ',
        healthProvider: 'Hospital B',
        minPrice: 952.280029296875,
        avgPrice: 4695.862342247596,
        maxPrice: 15474.8095703125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Ω04Χ',
        healthProvider: 'Hospital A',
        minPrice: 1831.3199462890625,
        avgPrice: 1831.3199462890625,
        maxPrice: 1831.3199462890625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ω04Χ',
        healthProvider: 'Hospital C',
        minPrice: 9424.5595703125,
        avgPrice: 9424.5595703125,
        maxPrice: 9424.5595703125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω05Α',
        healthProvider: 'Hospital A',
        minPrice: 9131.169921875,
        avgPrice: 9131.169921875,
        maxPrice: 9131.169921875,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω05Α',
        healthProvider: 'Hospital B',
        minPrice: 10054.1396484375,
        avgPrice: 10054.1396484375,
        maxPrice: 10054.1396484375,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω05Α',
        healthProvider: 'Hospital C',
        minPrice: 7416.240234375,
        avgPrice: 8639.875,
        maxPrice: 9863.509765625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω06Α',
        healthProvider: 'Hospital A',
        minPrice: 6372.68994140625,
        avgPrice: 6372.68994140625,
        maxPrice: 6372.68994140625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω06Α',
        healthProvider: 'Hospital B',
        minPrice: 6131.5400390625,
        avgPrice: 9105.48994140625,
        maxPrice: 13168.4697265625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 8,
      },
      {
        drg: 'Ω06Α',
        healthProvider: 'Hospital C',
        minPrice: 343.7300109863281,
        avgPrice: 9437.315776151769,
        maxPrice: 16539.349609375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω10Α',
        healthProvider: 'Hospital A',
        minPrice: 2184.199951171875,
        avgPrice: 3309.7726828835225,
        maxPrice: 5301.3798828125,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω10Α',
        healthProvider: 'Hospital B',
        minPrice: 1801.0999755859375,
        avgPrice: 6070.016351873224,
        maxPrice: 12223.650390625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω10Α',
        healthProvider: 'Hospital C',
        minPrice: 257.79998779296875,
        avgPrice: 6445.892350416917,
        maxPrice: 9393.73046875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω11Α',
        healthProvider: 'Hospital A',
        minPrice: 2784.43994140625,
        avgPrice: 2784.43994140625,
        maxPrice: 2784.43994140625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω11Α',
        healthProvider: 'Hospital B',
        minPrice: 769.3900146484375,
        avgPrice: 3307.1874283500338,
        maxPrice: 4864.22021484375,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Ω11Α',
        healthProvider: 'Hospital C',
        minPrice: 4140.1201171875,
        avgPrice: 4913.697509765625,
        maxPrice: 6411.85009765625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ω13Α',
        healthProvider: 'Hospital B',
        minPrice: 1104.25,
        avgPrice: 3184.1800711495534,
        maxPrice: 8738.4404296875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 10,
      },
      {
        drg: 'Ω14Α',
        healthProvider: 'Hospital C',
        minPrice: 8698.08984375,
        avgPrice: 8698.08984375,
        maxPrice: 8698.08984375,
        minHospitalizationDays: 5,
        avgHospitalizationDays: 5,
        maxHospitalizationDays: 5,
      },
      {
        drg: 'Ω15Α',
        healthProvider: 'Hospital C',
        minPrice: 8988.26953125,
        avgPrice: 8988.26953125,
        maxPrice: 8988.26953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω20Α',
        healthProvider: 'Hospital C',
        minPrice: 2353.590087890625,
        avgPrice: 2353.590087890625,
        maxPrice: 2353.590087890625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 0,
        maxHospitalizationDays: 0,
      },
      {
        drg: 'Ω31Α',
        healthProvider: 'Hospital A',
        minPrice: 1327.3199462890625,
        avgPrice: 2363.2100219726562,
        maxPrice: 3399.10009765625,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 3,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ω31Α',
        healthProvider: 'Hospital C',
        minPrice: 623.2000122070312,
        avgPrice: 3373.0009044300427,
        maxPrice: 6882.080078125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ω32Α',
        healthProvider: 'Hospital C',
        minPrice: 4226.43017578125,
        avgPrice: 4226.43017578125,
        maxPrice: 4226.43017578125,
        minHospitalizationDays: 2,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 2,
      },
      {
        drg: 'Ω33Α',
        healthProvider: 'Hospital B',
        minPrice: 1324.989990234375,
        avgPrice: 4172.782810211182,
        maxPrice: 13067.5,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 4,
        maxHospitalizationDays: 12,
      },
      {
        drg: 'Ω34Α',
        healthProvider: 'Hospital B',
        minPrice: 718.5499877929688,
        avgPrice: 2496.3934281512006,
        maxPrice: 6583.93017578125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 9,
      },
      {
        drg: 'Ω34Α',
        healthProvider: 'Hospital C',
        minPrice: 2533.010009765625,
        avgPrice: 3829.9034016927085,
        maxPrice: 6305.2001953125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 3,
      },
      {
        drg: 'Ω35Α',
        healthProvider: 'Hospital B',
        minPrice: 1477.8399658203125,
        avgPrice: 1477.8399658203125,
        maxPrice: 1477.8399658203125,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω36Χ',
        healthProvider: 'Hospital B',
        minPrice: 1881.8299560546875,
        avgPrice: 3410.9039794921873,
        maxPrice: 4127.85009765625,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 2,
        maxHospitalizationDays: 4,
      },
      {
        drg: 'Ω36Χ',
        healthProvider: 'Hospital C',
        minPrice: 3936.199951171875,
        avgPrice: 4721.4549560546875,
        maxPrice: 6937.5498046875,
        minHospitalizationDays: 0,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 6,
      },
      {
        drg: 'Ω37Α',
        healthProvider: 'Hospital A',
        minPrice: 3306.239990234375,
        avgPrice: 3306.239990234375,
        maxPrice: 3306.239990234375,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω37Α',
        healthProvider: 'Hospital B',
        minPrice: 2706.68994140625,
        avgPrice: 2706.68994140625,
        maxPrice: 2706.68994140625,
        minHospitalizationDays: 1,
        avgHospitalizationDays: 1,
        maxHospitalizationDays: 1,
      },
      {
        drg: 'Ω37Α',
        healthProvider: 'Hospital C',
        minPrice: 11270.2998046875,
        avgPrice: 11270.2998046875,
        maxPrice: 11270.2998046875,
        minHospitalizationDays: 7,
        avgHospitalizationDays: 7,
        maxHospitalizationDays: 7,
      },
    ]);
  }


  getIncidentClaims(): Observable<any> {
    return of([
      {
        incident: 'Παθολογικό (PATH)',
        claimVolume: 979,
        claimVolumePercentage: 18.75838283196,
        avgPrice: 4353.227832484784,
      },
      {
        incident: 'Χημειοθεραπεία (CHM)',
        claimVolume: 953,
        claimVolumePercentage: 18.260203104042,
        avgPrice: 879.021005325721,
      },
      {
        incident: 'Χειρουργικό μιας ημέρας (ODS)',
        claimVolume: 908,
        claimVolumePercentage: 17.39796895957,
        avgPrice: 3139.3647179233526,
      },
      {
        incident: 'Χειρουργικό (SURG)',
        claimVolume: 777,
        claimVolumePercentage: 14.887909561218,
        avgPrice: 8916.275181469327,
      },
      {
        incident: 'Γαστρο/Κολονοσκόπηση (G/C)',
        claimVolume: 469,
        claimVolumePercentage: 8.986395861276,
        avgPrice: 1214.842314068589,
      },
      {
        incident: 'Χειρουργικό – Χωρίς ανοικτή τομή / Σκοπικές (SURG2)',
        claimVolume: 406,
        claimVolumePercentage: 7.779268059015,
        avgPrice: 8616.751424944843,
      },
      {
        incident: 'Παθολογικό ODC (ODCTR)',
        claimVolume: 155,
        claimVolumePercentage: 2.969917608737,
        avgPrice: 763.8939866209341,
      },
      {
        incident: 'Επεμβατικές πράξεις (SURGOP)',
        claimVolume: 126,
        claimVolumePercentage: 2.414255604521,
        avgPrice: 6239.3739989749965,
      },
      {
        incident: 'Παρακολούθηση – Εξωτερικά Ιατρεία  (OUTD)',
        claimVolume: 120,
        claimVolumePercentage: 2.299291051925,
        avgPrice: 3157.39099260966,
      },
      {
        incident: 'Χειρουργικό – Εξωτερικά Ιατρεία (OUTS)',
        claimVolume: 114,
        claimVolumePercentage: 2.184326499329,
        avgPrice: 525.7725486007391,
      },
      {
        incident: 'Ογκολογικό (ONG)',
        claimVolume: 102,
        claimVolumePercentage: 1.954397394136,
        avgPrice: 10817.402635910932,
      },
      {
        incident: 'Ακτινοθεραπεία (RAD)',
        claimVolume: 71,
        claimVolumePercentage: 1.360413872389,
        avgPrice: 6024.142987932478,
      },
      {
        incident: 'Ρομποτική Χειρουργική (ROB)',
        claimVolume: 32,
        claimVolumePercentage: 0.613144280513,
        avgPrice: 15445.410629272461,
      },
      {
        incident: 'Διαγνωστικές Εξετάσεις (DT)',
        claimVolume: 6,
        claimVolumePercentage: 0.114964552596,
        avgPrice: 1143.800048828125,
      },
      {
        incident: '?e????????? (SURG)',
        claimVolume: 1,
        claimVolumePercentage: 0.019160758766,
        avgPrice: 7540.359283,
      },
    ]);
  }


  // Second Grade
  getDRGClaimAmount(): Observable<any> {
    return of([
      {
        drg: 'Α27Χ',
        hospitalA: 2090.115,
        hospitalB: 5211.168,
        hospitalC: 3865.997,
      },
      {
        drg: 'Γ22Α',
        hospitalA: 3457.17,
        hospitalB: 1684.63,
        hospitalC: 2003.72,
      },
      {
        drg: 'Κ37Χ',
        hospitalA: 2077.81,
        hospitalB: 3494.52,
        hospitalC: 3593.58,
      },
      {
        drg: 'Κ49ΜΑ',
        hospitalA: 11194.63,
        hospitalB: 22929.271,
        hospitalC: 18855.555,
      },
      {
        drg: 'Π07Χ',
        hospitalA: 7460.63,
        hospitalB: 8266.962,
        hospitalC: 9287.733,
      },
      {
        drg: 'Π10Χ',
        hospitalA: 5848.595,
        hospitalB: 5394.239,
        hospitalC: 9001.814,
      },
      {
        drg: 'Π46Α',
        hospitalA: 3200.265,
        hospitalB: 1814.481,
        hospitalC: 4440.826,
      },
      {
        drg: 'Π47Χ',
        hospitalA: 2144.173,
        hospitalB: 1934.199,
        hospitalC: 3962.477,
      },
      {
        drg: 'Υ23Χ',
        hospitalA: 5987.952,
        hospitalB: 3937.373,
        hospitalC: 4677.622,
      },
      {
        drg: 'Υ24Α',
        hospitalA: 4012.311,
        hospitalB: 6955.654,
        hospitalC: 5198.26,
      },
      {
        drg: 'Ω05Α',
        hospitalA: 9131.17,
        hospitalB: 10054.14,
        hospitalC: 8639.875,
      },
      {
        drg: 'Ω06Α',
        hospitalA: 6372.69,
        hospitalB: 9105.49,
        hospitalC: 9437.316,
      },
    ]);
  }
  getHospitalazationDurations(): Observable<any> {
    return of([
      { drg: 'Α27Χ', hospitalA: 2, hospitalB: 4, hospitalC: 2 },
      { drg: 'Γ22Α', hospitalA: 3, hospitalB: 2, hospitalC: 1 },
      { drg: 'Κ37Χ', hospitalA: 1, hospitalB: 3, hospitalC: 1 },
      { drg: 'Κ49ΜΑ', hospitalA: 1, hospitalB: 1, hospitalC: 1 },
      { drg: 'Π07Χ', hospitalA: 2, hospitalB: 2, hospitalC: 2 },
      { drg: 'Π10Χ', hospitalA: 0, hospitalB: 1, hospitalC: 1 },
      { drg: 'Π46Α', hospitalA: 3, hospitalB: 2, hospitalC: 3 },
      { drg: 'Π47Χ', hospitalA: 1, hospitalB: 1, hospitalC: 2 },
      { drg: 'Υ23Χ', hospitalA: 1, hospitalB: 1, hospitalC: 2 },
      { drg: 'Υ24Α', hospitalA: 0, hospitalB: 1, hospitalC: 0 },
      { drg: 'Ω05Α', hospitalA: 2, hospitalB: 2, hospitalC: 1 },
      { drg: 'Ω06Α', hospitalA: 1, hospitalB: 2, hospitalC: 0 },
    ]);
  }
  getMedicalExpPerc(): Observable<any> {
    return of([
      {
        healthProvider: 'Hospital A',
        medicalSuppliesPercentage: 21.82894677668015,
        specialMedicalSuppliesPercentage: 39.032040268855596,
        hospitalizationPercentage: 11.548821726062247,
        medicalExaminationsPercentage: 33.82657699338984,
        surgeryExpensesPercentage: 10.3104743642244,
        physicianFeesPercentage: 2.3919519144944132,
        otherExpensesPercentage: 51.469455364882776,
      },
      {
        healthProvider: 'Hospital C',
        medicalSuppliesPercentage: 33.57329963932502,
        specialMedicalSuppliesPercentage: 27.85828833701303,
        hospitalizationPercentage: 14.880705932219632,
        medicalExaminationsPercentage: 36.87664973337683,
        surgeryExpensesPercentage: 9.245210504946998,
        physicianFeesPercentage: 5.426925504249639,
        otherExpensesPercentage: 38.88862752983779,
      },
      {
        healthProvider: 'Hospital B',
        medicalSuppliesPercentage: 34.119007004970264,
        specialMedicalSuppliesPercentage: 21.60130018974516,
        hospitalizationPercentage: 15.153255599903607,
        medicalExaminationsPercentage: 26.725754853338742,
        surgeryExpensesPercentage: 12.424127222677123,
        physicianFeesPercentage: 8.811803674396911,
        otherExpensesPercentage: 35.50515019838384,
      },
    ]);
  }
  getClinicClaims(): Observable<any> {
    return of([
      {
        clinic: 'Ογκολογική',
        hospitalA: 308134.8,
        hospitalB: 486252.5,
        hospitalC: 357439.7,
      },
      {
        clinic: 'Γενική χειρουργική',
        hospitalA: 304776.7,
        hospitalB: 213346.7,
        hospitalC: 401939.0,
      },
      {
        clinic: 'Γαστρεντερολογική',
        hospitalA: 222820.9,
        hospitalB: 860870.9,
        hospitalC: 1283418.0,
      },
      {
        clinic: 'Παιδιατρική',
        hospitalA: 187233.8,
        hospitalB: 607421.7,
        hospitalC: 291409.1,
      },
      {
        clinic: 'Παθολογική',
        hospitalA: 324999.0,
        hospitalB: 1005953.0,
        hospitalC: 28528.39,
      },
      {
        clinic: 'Γυναικολογική',
        hospitalA: 35294.36,
        hospitalB: 7908.07,
        hospitalC: 692052.7,
      },
      {
        clinic: 'Ορθοπαιδική',
        hospitalA: 582987.0,
        hospitalB: 789938.3,
        hospitalC: 1488735.0,
      },
      {
        clinic: 'Ουρολογική',
        hospitalA: 621492.6,
        hospitalB: 239627.8,
        hospitalC: 587149.7,
      },
      {
        clinic: 'Ωτορινολαρυγγολογική',
        hospitalA: 48734.17,
        hospitalB: 395462.7,
        hospitalC: 59905.92,
      },
      {
        clinic: 'Καρδιολογική',
        hospitalA: 1120618.0,
        hospitalB: 285660.9,
        hospitalC: 207352.3,
      },
      {
        clinic: 'Αιματολογική',
        hospitalA: 118968.2,
        hospitalB: 576200.2,
        hospitalC: 235815.4,
      },
      {
        clinic: 'Νευροχειρουργική',
        hospitalA: 325348.2,
        hospitalB: 7343.97,
        hospitalC: 372213.1,
      },
      {
        clinic: 'Νευρολογική',
        hospitalA: 366644.0,
        hospitalB: 371503.4,
        hospitalC: 1114228.0,
      },
      {
        clinic: 'Δερματολογική',
        hospitalA: 443357.7,
        hospitalB: 172489.2,
        hospitalC: 222438.8,
      },
      {
        clinic: 'Παιδοχειρουργική',
        hospitalA: 463091.1,
        hospitalB: 24048.87,
        hospitalC: 425460.5,
      },
      {
        clinic: 'Χειρουργός μαστού',
        hospitalA: 429984.5,
        hospitalB: 91450.97,
        hospitalC: 53439.88,
      },
      {
        clinic: 'Ακτινοθεραπευτική',
        hospitalA: 146720.8,
        hospitalB: 175038.1,
        hospitalC: 326932.9,
      },
      {
        clinic: 'Οφθαλμιατρική',
        hospitalA: 223519.8,
        hospitalB: 1724626.0,
        hospitalC: 41672.55,
      },
    ]);
  }
}
