import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private http = inject(HttpClient);

  private apiUrl = 'https://countriesnow.space/api/v0.1/countries';

  getCountries(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }
}
