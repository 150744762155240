<div>
  <div
    class="flex align-items-center py-0 px-3 w-full z-2 px-5"
    style="
      height: 65px;
      box-shadow: 0 10px 40px 0 rgb(41 50 65 / 6%);
      background: var(--surface-card);
    "
  >
    <a [routerLink]="['/']" class="app-logo cursor-pointer">
      <img
        src="../../assets/layout/images/phidelity_logo.png"
        class="p-p-3 p-mb-3"
        style="width: 60px; height: auto"
      />
    </a>
  </div>
  <div
    class="flex flex-column justify-content-center align-items-center px-4"
    style="min-height: calc(100vh - 62px)"
  >
    <div
      class="flex flex-column justify-content-center align-items-center text-center"
    >
      <img
        src="assets/layout/images/pages/asset-404.svg"
        alt="freya-layout"
        style="margin-bottom: -150px; width: 332px; height: 271px"
      />
      <span class="" style="font-size: 140px; line-height: 171px">404</span>
      <span class="block text-300 text-center font-medium"
        >{{ "NOTFOUND.LOST" | translate }}
      </span>
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'NOTFOUND.HOME' | translate }}"
        class="mt-4 font-medium p-button-lg p-button"
        [routerLink]="['/']"
      ></button>
      <div class="mt-5">
        <button
          type="button"
          (click)="toggleDropdown()"
          style="border: none; background: transparent; cursor: pointer"
        >
          <img
            [src]="selectedLanguageIcon"
            alt="Selected Language"
            style="width: 20px; height: auto"
          />
        </button>
        @if(dropdownVisible){
        <div class="dropdown-menu" style="position: absolute; z-index: 1000">
          @for(lang of languages;track $index){
          <div
            (click)="switchLanguage(lang.value)"
            style="
              display: flex;
              align-items: center;
              cursor: pointer;
              padding: 5px;
            "
          >
            <img
              [src]="lang.icon"
              alt="{{ lang.label }}"
              style="width: 15px; height: auto; margin-right: 5px"
            />
            {{ lang.label }}
          </div>
          }
        </div>
        }
      </div>
    </div>
    <div class="flex flex-wrap align-items-center pt-8 pb-4 px-4">
      <h4 class="m-0 mr-5">PHI</h4>
      <!-- <h6 class="m-0 mt-1 text-300 font-medium">Copyright Ⓒ Covariance P.C.</h6> -->
    </div>
  </div>
</div>
