<div class="card">
  <p-accordion>
    <p-accordionTab
      [header]="header"
      [selected]="false"
      class="line-height-3 m-0"
      >
      <div class="flex align-items-center flex-wrap gap-4 my-2 ml-2">
        @for(option of dropdownOptions; track option; let i = $index){
          <div class="">
            <p-dropdown
              #dropdown
              [options]="option"
              [(ngModel)]="selectedOptions[i]"
              optionLabel="label"
              optionValue="value"
              [placeholder]="placeholderOptions[i]"
              class=""
            ></p-dropdown>
          </div>
          } @for(placeholder of inputPlaceholders; track placeholder; let i =
          $index){
          <div class="">
            <div>
              <span class="p-input-icon-right">
                @if(placeholder.icon){
                  <i class="pi pi-sync"></i>
                }
                <input type="text" pInputText [placeholder]="placeholder.label" />
              </span>
            </div>
          </div>
          } @if(checkbox == true){
          <div>
            <p-dropdown
              #dropdown
            [options]="[
              { label: 'Ναι', value: 'ναι' },
              { label: 'Όχι', value: 'όχι' }
            ]"
              optionLabel="label"
              optionValue="value"
              placeholder="Χρήση Παραπεμπτικού;"
              class=""
            ></p-dropdown>
          </div>
        }
        @if(insurance == true){
          <div>
            <p-dropdown
              #dropdown
              [options]="[
                { label: 'Εθνική Ασφαλιστική Α.Ε.Ε.Γ.Α', value: 'ethniki' },
                { label: 'Generali Hellas', value: 'generali' },
                { label: 'NN Hellas', value: 'nn' },
                { label: 'Interamerican', value: 'interamerican' },
                { label: 'Eurolife ERB', value: 'eurolife' },
              ]"
              optionLabel="label"
              optionValue="value"
              placeholder="Επιλογή Ασφαλιστικής"
              class=""
            ></p-dropdown>
          </div>
        }
        @if(enableNearbyFilter){
          <div>
            <label>
              <input type="checkbox" [(ngModel)]="showNearbyDoctors" [disabled]="!acceptedLocationPermission" pTooltip="Location permission is required to enable this filter" [tooltipDisabled]="acceptedLocationPermission" tooltipPosition="bottom" />
              Κοντά μου
            </label>
          </div>
          @if (showNearbyDoctors || showNearbyClinic) {
            <div class="my-2">
              <label for="distanceSlider">Απόσταση (χλμ):</label>
              <p-slider
                [(ngModel)]="selectedDistance"
                [min]="1"
                [max]="60"
                [step]="1"
                [style]="{ width: '300px' }"
              ></p-slider>
              <span>{{ selectedDistance }} χλμ</span>
            </div>
          }
          } @if(enableNearbyClinicFilter){
          <div>
            <label>
              <input type="checkbox" [(ngModel)]="showNearbyClinic" [disabled]="!acceptedLocationPermission" pTooltip="Location permission is required to enable this filter" [tooltipDisabled]="acceptedLocationPermission" tooltipPosition="bottom"/>
              Κοντά μου
            </label>
          </div>
          @if (showNearbyDoctors || showNearbyClinic) {
            <div class="my-2">
              <label for="distanceSlider">Απόσταση (χλμ):</label>
              <p-slider
                [(ngModel)]="selectedDistance"
                [min]="1"
                [max]="60"
                [step]="1"
                [style]="{ width: '300px' }"
              ></p-slider>
              <span>{{ selectedDistance }} χλμ</span>
            </div>
          }
        }
        <div
          class="flex flex-wrap md:justify-content-end align-items-center column-gap-3 row-gap-4"
          >
          <p-button class="" (click)="searchData()"> Αναζήτηση </p-button>
          @if(dataFiltered){
            <p-button class="gap-2" severity="danger" (click)="resetFilters()">
              Εμφάνιση Όλων
            </p-button>
          }
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
