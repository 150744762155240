import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DoctorDTO } from '../../interfaces/doctor';
import { environment } from '@shared/environments';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  private http = inject(HttpClient);

  readonly authHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  doctors: any[] = [
    {
      doctorId: 1,
      userId: 1,
      fullName: 'Αβραμίδης Γρηγόριος',
      degrees: 'MD, MSc',
      specialty: 'Αθλητίατρος',
      avatarUrl:
        'https://cdn.doctoranytime.gr/profileimages/thumbs/0450f0dd-4ab8-46d0-abcd-785d34635a23.jpg',
      description:
        'Ο Αβραμίδης Γρηγόριος MD, MSc είναι Ορθοπαιδικός Χειρουργός - Αθλητίατρος, Επιμελητής των Ιδιωτικών Θεραπευτηρίων "ΥΓΕΙΑ" και "ΜΗΤΕΡΑ" και διατηρεί ιδιωτικά ιατρεία στη Χαλκίδα και το Μαρούσι Αττικής.',
      fullDescription:
        'Γεννήθηκε και μεγάλωσε στην Χαλκίδα και κατάγεται από το Ναύπλιο. Είναι απόφοιτος της Ιατρικής Σχολής του Πανεπιστημίου Πατρών και κάτοχος του Μεταπτυχιακού Τίτλου Οστεοπόρωση και Μεταβολικά Νοσήματα των Οστών της Ιατρικής Σχολής του Πανεπιστημίου Αθηνών. Εξειδικεύεται στην Αρθροσκοπική Χειρουργική, την Αρθροπλαστική & Ρομποτική Αρθροπλαστική, την Χειρουργική Άκρας Χειρός - Μικροχειρουργική και τις Αθλητικές Κακώσεις. Έχει λάβει πολλαπλές υποτροφίες, ενώ έχει συμμετάσχει σε πολλά σεμινάρια και συνέδρια στην Ελλάδα και στο εξωτερικό. Τέλος, ο γιατρός είναι επίσημα πιστοποιημένος στην Ρομποτική Αρθροπλαστική Ισχίου και Γόνατος.',
      addresses: [
        {
          fullAddress: 'Αβάντων 24, Χαλκίδα',
          lat: 38.464981,
          lon: 23.594667,
          practiceId: 15498,
        },
        {
          fullAddress: 'Ερυθρού Σταυρού 5, Μαρούσι',
          lat: 38.027515,
          lon: 23.789619,
          practiceId: 15499,
        },
      ],
      nextAvailabilityDate: '2024-07-16-18-15',
      rating: 9.9,
      cost: 0,
      availableDates: [
        '2024-07-16-18-15',
        '2024-07-18-16-15',
        '2024-07-25-15-45',
      ],
      experiences: ['Αρθροπλαστική', 'Αρθροσκόπηση'],
      area: 'Μαρούσι',
      registrationVerified: false,
      totalPatients: 1000,
      service: [
        {
          serviceName: 'Αρθροσκοπική Χειρουργική Ώμου',
          servicePrice: '€1600',
        },
        {
          serviceName: 'Αρθροσκοπική Χειρουργική Γόνατος',
          servicePrice: '€1500',
        },
        {
          serviceName: 'Αρθροσκοπική Χειρουργική Αγκώνα',
          servicePrice: '€1400',
        },
        {
          serviceName: 'Αρθροσκοπική Χειρουργική Ποδοκνημικής',
          servicePrice: '€1700',
        },
        {
          serviceName: 'Θεραπεία Αθλητικών Κακώσεων',
          servicePrice: '€1300',
        },
        {
          serviceName: 'Υπερηχογραφία Μυοσκελετικού Συστήματος',
          servicePrice: '€500',
        },
      ],
    },
    {
      doctorId: 2,
      userId: 2,
      fullName: 'Σταθέλλης Απόστολος',
      degrees: 'MD, PhD',
      specialty: 'Ορθοπεδικός',
      avatarUrl:
        'https://cdn.doctoranytime.gr/profileimages/thumbs/517e76ca-c3a0-4be8-9eab-f1c4563f3620.webp',
      description:
        'Ο Σταθέλλης Απόστολος MD, PhD είναι Ορθοπαιδικός Χειρουργός - Αθλητίατρος με εξειδίκευση στην αρθροσκοπική χειρουργική ώμου, γόνατος, αγκώνα και ποδοκνημικής, καθώς και τις αθλητικές κακώσεις.',
      fullDescription:
        'Είναι αριστούχος απόφοιτος της Ιατρικής Σχολής του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών και Διδάκτωρ του Πανεπιστημίου Ουλμ της Γερμανίας. Μετά από 8ετή εκπαίδευση στην Γερμανία (2009 - 2016) σε ένα από τα κορυφαία κέντρα αρθροσκοπικής χειρουργικής (Sportklinik- Stuttgart) διατηρεί πλέον ιδιωτικά ιατρεία στα Βριλήσσια και στο Μαρούσι και διατελεί παράλληλα Επιμελητής στην Γ’ Ορθοπαιδική Κλινική στο Νοσοκομείο Υγεία. Το 2016 έλαβε στη Γερμανία τον τίτλο εξειδίκευσης της Αθλητιατρικής, καθώς επίσης και την πιστοποίηση στην αρθροσκοπική χειρουργική. Τέλος, ο γιατρός είναι εκπαιδευμένος και πιστοποιημένος στην υπερηχογραφία του μυοσκελετικού συστήματος.',
      addresses: [
        {
          fullAddress: 'Λεωφόρος Πεντέλης 48, Βριλήσσια',
          lat: 38.464981,
          lon: 23.594667,
          practiceId: 15498,
        },
        {
          fullAddress: 'Ερυθρού Σταυρού 5, Μαρούσι',
          lat: 38.027515,
          lon: 23.789619,
          practiceId: 15499,
        },
      ],
      nextAvailabilityDate: '2024-07-16-18-15',
      rating: 9.9,
      availableDates: [
        '2024-07-16-18-15',
        '2024-07-18-16-15',
        '2024-07-25-15-45',
      ],
      experiences: ['Αρθροσκόπηση'],
      area: 'Μαρούσι',
      cost: 0,
      registrationVerified: true,
      totalPatients: 1000,
      service: [
        {
          serviceName: 'Αρθροπλαστική',
          servicePrice: '€2000',
        },
        {
          serviceName: 'Αρθροσκόπηση',
          servicePrice: '€1500',
        },
        {
          serviceName: 'Ρομποτική Αρθροπλαστική',
          servicePrice: '€3000',
        },
        {
          serviceName: 'Χειρουργική Άκρας Χειρός',
          servicePrice: '€1800',
        },
        {
          serviceName: 'Μικροχειρουργική',
          servicePrice: '€2500',
        },
        {
          serviceName: 'Θεραπεία Οστεοπόρωσης',
          servicePrice: '€1200',
        },
      ],
    },
  ];
  services: any = [
    {
      id: 1,
      fullName: 'Αβραμίδης Γρηγόριος',
      services: [
        {
          service: 'Αρθροπλαστική',
          price: '€2000',
        },
        {
          service: 'Αρθροσκόπηση',
          price: '€1500',
        },
        {
          service: 'Ρομποτική Αρθροπλαστική',
          price: '€3000',
        },
        {
          service: 'Χειρουργική Άκρας Χειρός',
          price: '€1800',
        },
        {
          service: 'Μικροχειρουργική',
          price: '€2500',
        },
        {
          service: 'Θεραπεία Οστεοπόρωσης',
          price: '€1200',
        },
      ],
    },
    {
      id: 5,
      fullName: 'Αβραμίδης Γρηγόριος',
      services: [
        {
          service: 'Απλή επίσκεψη',
          price: '€50',
        },
        {
          service: 'Θεραπεία λεύκης',
          price: '€300',
        },
        {
          service: 'Πιστοποιητικά/Βεβαιώσεις',
          price: '€30',
        },
        {
          service: 'Καθαρισμός με δερμοαπόξεση',
          price: '€120',
        },
        {
          service: 'Αντιμετώπιση καρκίνου του δέρματος',
          price: '€2000',
        },
        {
          service: 'Αντιμετώπιση τριχόπτωσης',
          price: '€400',
        },
        {
          service: 'Έλεγχος σπίλων (ελιές)',
          price: '€100',
        },
        {
          service: 'Θεραπεία αντιγήρανσης με υαλουρονικό οξύ (fillers)',
          price: '€500',
        },
        {
          service: 'Τριχοριζόγραμμα',
          price: '€200',
        },
        {
          service: 'Θεραπεία ουλών ακμής',
          price: '€350',
        },
      ],
    },
    {
      id: 2,
      fullName: 'Σταθέλλης Απόστολος',
      services: [
        {
          service: 'Αρθροσκοπική Χειρουργική Ώμου',
          price: '€1600',
        },
        {
          service: 'Αρθροσκοπική Χειρουργική Γόνατος',
          price: '€1500',
        },
        {
          service: 'Αρθροσκοπική Χειρουργική Αγκώνα',
          price: '€1400',
        },
        {
          service: 'Αρθροσκοπική Χειρουργική Ποδοκνημικής',
          price: '€1700',
        },
        {
          service: 'Θεραπεία Αθλητικών Κακώσεων',
          price: '€1300',
        },
        {
          service: 'Υπερηχογραφία Μυοσκελετικού Συστήματος',
          price: '€500',
        },
      ],
    },
  ];

  private apiUrl = `${environment.api}`;

  getDoctorDataByUsername() {
    return this.http.get<any>(`${this.apiUrl}doctors/me`, this.httpOptions);
  }

  getAllDoctors(pageSize: number, pageNumber: number): Observable<any> {
    // let params: any = { pageSize: pageSize, pageNumber: pageNumber };

    return this.http.get<any>(
      `${this.apiUrl}doctors?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  }
  getDoctorByIdV2(doctorId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${doctorId}`,
      this.httpOptions
    );
  }
  getAllPatientsByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/patients`,
      this.httpOptions
    );
  }

  getDoctors(): Observable<any> {
    return of(this.doctors);
  }

  getCustomerRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/customer-record`,
      this.httpOptions
    );
  }

  getTransactionRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/transaction-record`,
      this.httpOptions
    );
  }

  getDiagnosisRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/diagnosis-record`,
      this.httpOptions
    );
  }

  getVisitRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/visit-record`,
      this.httpOptions
    );
  }

  getExaminationRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/examination-record`,
      this.httpOptions
    );
  }

  getFileRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/file-record`,
      this.httpOptions
    );
  }

  getPrescriptionRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/prescription-record`,
      this.httpOptions
    );
  }

  getReferralRecordByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/referral-record`,
      this.httpOptions
    );
  }

  getChartDataByDoctorUserId(userId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctors/${userId}/chart-data`,
      this.httpOptions
    );
  }
  getAccessRequestsToMedicalRecord(userId: string) {
    /* TODO */
    return this.http.get<any>(
      `${this.apiUrl}doctors/accessRequests?userId=${userId}`,
      this.httpOptions
    );
  }
  createAccessRequestToMedicalRecord(createRequest: {
    requestUserId: string;
    targetUserId: string;
  }) {
    /* TODO */
    return this.http.get<any>(
      `${this.apiUrl}doctors/requestAccess`,
      this.httpOptions
    );
  }
}
