<div class="card p-4 flex flex-column h-full">
  <div class="text-2xl text-primary font-bold mb-3 flex align-items-center">
    <span class="p-badge text-white p-badge-no-gutter p-badge-lg mr-2"
      ><i class="pi pi-user"></i
    ></span>
    Προσωπικά Στοιχεία​
  </div>
  <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
  <p-tabView>
    @for(tab of tabs;track tab){
    <p-tabPanel [header]="tab.title">
      <div class="grid">
        @for(usr of tab.content; track usr){
        <div
          class="col-12 flex align-items-center justify-content-between py-3 px-2"
        >
          <div
            style="overflow: hidden; overflow-wrap: break-word"
            class="text-900 w-full md:w-6 font-bold text-lg"
          >
            <i class="mr-2" [ngClass]="usr.icon"></i>
            {{ usr.label }}:
          </div>
          @if(usr.key=="dateOfBirth"){
          <div
            style="overflow: hidden; overflow-wrap: break-word"
            class="text-600 w-full md:w-6 md:flex-order-0 flex-order-1 text-lg text-lg"
          >
            {{ userData[usr.key] | date : "dd/MM/yyyy" }}
          </div>
          }@else {
          <div
            style="overflow: hidden; overflow-wrap: break-word"
            class="text-600 w-full md:w-6 md:flex-order-0 flex-order-1 text-lg text-lg"
          >
            {{ userData[usr.key] }}
          </div>
          }
        </div>
        <hr class="col-12 my-3 mx-0 border-top-1 border-none surface-border" />
        }
      </div>
    </p-tabPanel>
    }
  </p-tabView>
</div>
