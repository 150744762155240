import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { environment } from '@shared/environments';
import Talk from 'talkjs';
import { AuthService } from '../../services/auth/auth.service';
import { ChatService } from '../../services/chat/chat.service';

@Component({
  selector: 'lib-talkjs-chat',
  standalone: true,
  imports: [],
  templateUrl: './talkjs-chat.component.html',
  styleUrl: './talkjs-chat.component.css',
})
export class TalkjsChatComponent implements OnInit,OnDestroy {
  private talkService = inject(ChatService);
  auth = inject(AuthService);

  private popup!: Talk.Popup | Talk.Chatbox;
  private session!: Talk.Session;
  @ViewChild('talkjsContainer') talkjsContainer!: ElementRef;
  ngOnDestroy(): void {
    if(this.popup){
      this.popup.destroy();
    }
  }
  ngOnInit(): void {
    // this.createPopup();
  }
  // private async createPopup() {
  //    this.session = await this.talkService.createCurrentSession();
  //   this.popup = await this.talkService.createPopup(this.session,' ');
  //   this.popup.mount(this.talkjsContainer.nativeElement);
  // }
}
