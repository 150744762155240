import { Component, ElementRef, ViewChild, AfterViewInit, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { AppSidebarComponent } from './app.sidebar.component';
import { ColorScheme, LayoutService } from './service/app.layout.service';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { NotificationsService } from '../../services/shared/notifications.service';
import { filter, Subject, Subscription } from 'rxjs';
import { MemberService } from '../../services/member/member.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ChatService } from '../../services/chat/chat.service';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

import { StyleClassModule } from 'primeng/styleclass';
import { Ripple } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { MessagesModule } from 'primeng/messages';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [MessageService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    AppSidebarComponent,
    ButtonDirective,
    TooltipModule,
    StyleClassModule,
    Ripple,
    ToastModule,
    PrimeTemplate,
    SidebarModule,
    MessagesModule,
    TranslateModule
],
})
export class AppTopbarComponent implements AfterViewInit, OnInit {
  layoutService = inject(LayoutService);
  el = inject(ElementRef);
  private messageService = inject(MessageService);
  private memberService = inject(MemberService);
  private notificationService = inject(NotificationsService);
  private authService = inject(AuthService);
  private translate = inject(TranslateService);
  private chatService = inject(ChatService);
  router = inject(Router);

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
  @ViewChild('notification', { static: true }) notificationElement!: ElementRef;
  @ViewChild('dropdown') dropdown!: Dropdown;
  activeItem!: number;
  private subscriptions: Subscription[] = [];
  notificationCount: number = 0;
  sidebarVisible = false;
  newMessages: any[] = [];
  newNotifications: any[] = [];
  messageCount: number = 0;

  selectedLanguage: string;
  selectedLanguageIcon: string = '';
  dropdownVisible: boolean = false;

  languages = [
    { label: 'Ελληνικά', value: 'gr', icon: '../../assets/i18n/icons/gr.png' },
    { label: 'English', value: 'en', icon: '../../assets/i18n/icons/en.png' },
  ];
  appName: any;

  themeMode = 'pi pi-moon';
  constructor() {
    const defaultLanguage = 'gr';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    this.selectedLanguage = defaultLanguage;
    this.updateSelectedLanguageIcon();
  }

  ngOnInit() {
    this.appName = this.memberService.appName;

    let objName = this.appName;
    if (this.appName == 'insured' || this.appName == 'doctor') {
      objName = 'data';
    }

    let userName = JSON.parse(localStorage.getItem('token')!);

    const userSubscription$ = this.authService.currentUser$
      .pipe(
        filter((user) => {
          console.log('user topbar', user);
          return !!user;
        })
      )
      .subscribe((user) => {
        if (user) {
          const userId = user[objName]?.userId;
          if (!(this.appName == 'insurers')) {
            // const notificationSubscription$ = this.notificationService
            //   .startPolling(userId)
            //   .subscribe((notifications) => {
            //     if (notifications && notifications.data) {
            //       this.processNewNotifications(notifications.data);
            //     }
            //   });
            // this.subscriptions.push(notificationSubscription$);
          }

          if (objName == 'data') {
            // const messageSubscription$ = this.chatService
            //   .startPolling(userId)
            //   .subscribe({
            //     next: (conversations) => {
            //       this.processNewMessages(conversations.data);
            //     },
            //     error: (error) => {
            //       console.error('Polling error:', error);
            //     },
            //   });
            // this.subscriptions.push(messageSubscription$);
          }
        }
      });

    this.subscriptions.push(userSubscription$);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.notificationService.stopPollingForNotifications();

    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('selectedLanguage', language);
    this.selectedLanguage = language;
    this.updateSelectedLanguageIcon();
    this.dropdownVisible = false;
  }

  private updateSelectedLanguageIcon() {
    const foundLanguage = this.languages.find(
      (lang) => lang.value === this.selectedLanguage
    );
    this.selectedLanguageIcon = foundLanguage ? foundLanguage.icon : '';
  }

  private processNewMessages(messages: any): void {
    if (!messages || !Array.isArray(messages)) {
      return;
    }

    messages.forEach((msg: any) => {
      const existingMsgIndex = this.newMessages.findIndex(
        (existingMsg: any) => existingMsg.id === msg.id
      );

      if (existingMsgIndex !== -1) {
        this.newMessages[existingMsgIndex] = msg;
      } else {
        this.newMessages.push(msg);
      }
    });

    this.updateMessageCount();
  }

  private updateMessageCount(): void {
    this.messageCount = this.newMessages.filter(
      (item: any) => item.unreadMessageCount >= 1
    ).length;
  }
  goToInbox() {
    this.messageCount = 0;

    this.router.navigate(['/messages']);
  }
  goToSettings() {
    this.router.navigate(['/settings']);
  }

  private processNewNotifications(notifications: any[]): void {
    const newNotifications = notifications
      .map((item) => {
        const exists = this.newNotifications.some(
          (msg) => msg.id === item.notificationId
        );
        if (!exists) {
          this.messageService.addAll([
            {
              severity: 'info',
              summary: 'Ειδοποίηση',
              detail: item.message,
            },
          ]);
          return {
            id: item.notificationId,
            severity: 'info',
            summary: '',
            detail: item.message,
            viewed: false,
          };
        }
        return null;
      })
      .filter(Boolean);

    this.newNotifications = [...this.newNotifications, ...newNotifications];

    this.updateNotificationCount();
  }

  private updateNotificationCount(): void {
    this.notificationCount = this.newNotifications.filter(
      (item) => !item.viewed
    ).length;
  }

  discardMessage(message: any): void {
    this.notificationService
      .deleteNotification([message.id])
      .subscribe((res) => {
        this.newNotifications = this.newNotifications.filter(
          (msg) => msg.id !== message.id
        );
        this.updateNotificationCount();
      });
  }

  clearAllNotifications(): void {
    const notificationIds = this.newNotifications.map((item) => item.id);
    this.notificationService
      .deleteNotification(notificationIds)
      .subscribe((res) => {
        this.newNotifications = [];
        this.notificationCount = 0;
      });
  }

  onSidebarClose(): void {
    this.newNotifications.forEach(
      (item) => ((item.viewed = true), (item.severity = 'info'))
    );
    this.updateNotificationCount();

    this.sidebarVisible = false;
  }

  showSidebar(): void {
    this.sidebarVisible = true;
    this.messageService.clear();

    this.newNotifications = [...this.newNotifications.map((x) => ({ ...x }))];
  }

  logout() {
    this.notificationService.stopPollingForNotifications();
    this.authService.logout();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onSidebarButtonClick() {
    this.layoutService.showSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  get colorScheme(): ColorScheme {
    return this.layoutService.config().colorScheme;
  }
  set colorScheme(_val: ColorScheme) {
    this.layoutService.config.update((config) => ({
      ...config,
      colorScheme: _val,
    }));
    this.layoutService.config.update((config) => ({
      ...config,
      menuTheme: _val,
    }));
    const tobarTheme =
      this.layoutService.config().topbarTheme === 'transparent'
        ? 'transparent'
        : _val;

    this.layoutService.config.update((config) => ({
      ...config,
      topbarTheme: tobarTheme,
    }));
  }
  goToMessages() {
    window.open('/messages');
  }
  changeThemeMode() {
    if (this.colorScheme == 'light') {
      this.colorScheme = 'dark';
      this.themeMode = 'pi pi-sun';
    } else {
      this.colorScheme = 'light';
      this.themeMode = 'pi pi-moon';
    }
  }
}
