import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { RouterLink } from '@angular/router';
import { AppMenuComponent } from './app.menu.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
    standalone: true,
    imports: [RouterLink, AppMenuComponent],
})
export class AppSidebarComponent {
  layoutService = inject(LayoutService);
  el = inject(ElementRef);

  timeout: any = null;
  logoSrc = '';
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  constructor() {this.logoSrc=this.layoutService.logoUrl()}

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.sidebarActive = false),
          300
        );
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
}
