import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private auth = inject(AuthService);
  private router = inject(Router);


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.currentUser$.pipe(
      map((auth) => {
        if (auth) {
          return true;
        }

        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      })
    );
  }
}
