import { Component, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLink } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { Title } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../layout/service/app.layout.service';
import { AuthService } from '../../../services/auth/auth.service';
import { MemberService } from '../../../services/member/member.service';
import { InsurerConfigService } from '../../../services/insurances/insurer-config.service';
import { ChatService } from '../../../services/chat/chat.service';

@Component({
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
  ],
})
export class LoginComponent {
  private layoutService = inject(LayoutService);
  private auth = inject(AuthService);
  private router = inject(Router);
  private memberService = inject(MemberService);
  private insurerConfigService = inject(InsurerConfigService);
  private titleService = inject(Title);
  private chatService = inject(ChatService);
  private translate = inject(TranslateService);

  rememberMe: boolean = false;
  email: string = '';
  password: string = '';
  selectedLanguage: string;
  selectedLanguageIcon: string = '';
  dropdownVisible: boolean = false;

  constructor() {
    const defaultLanguage = 'gr';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    this.selectedLanguage = defaultLanguage;
    this.updateSelectedLanguageIcon();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible; // Toggle visibility
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('selectedLanguage', language);
    this.selectedLanguage = language;
    this.updateSelectedLanguageIcon();
    this.dropdownVisible = false; // Close the dropdown after selection
  }

  private updateSelectedLanguageIcon() {
    const foundLanguage = this.languages.find(
      (lang) => lang.value === this.selectedLanguage
    );
    this.selectedLanguageIcon = foundLanguage ? foundLanguage.icon : '';
  }

  languages = [
    { label: 'Ελληνικά', value: 'gr', icon: '../../assets/i18n/icons/gr.png' },
    { label: 'English', value: 'en', icon: '../../assets/i18n/icons/en.png' },
  ];

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
  get dark(): boolean {
    return this.layoutService.config().colorScheme !== 'light';
  }

  login() {
    this.auth.login(this.email, this.password).subscribe({
      next: (response) => {
        // localStorage.setItem('token', JSON.stringify(response));
        this.setInsurersAppConfig(response.name);
        this.router.navigate(['']);
      },
      error: (error) => {
        // Handle login error
        console.error(error);
      },
      complete: () => {},
    });
  }

  setInsurersAppConfig(username: string | undefined): void {
    if (this.memberService.appName == 'insurers') {
      this.insurerConfigService.setInsurerConfig(username);
    }
  }
}
