<div class="surface-0">
  <div class="flex align-items-center justify-content-between flex-column h-screen">
    <div class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4">
      <a [routerLink]="['/']" class="mb-6">
        <!-- <img src="../../assets/layout/images/phi-logo.png" class="w-16rem"> -->
        <!-- <img src="../../assets/layout/images/phi_eurolife.png" class="w-10rem"> -->
        <img src="../../assets/layout/images/phidelity_logo.png" class="w-10rem">
      </a>
      <div class="flex flex-column" >
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-envelope"></i>
          <input id="email" type="text" pInputText class="w-full md:w-25rem text-color-secondary surface-50 border-200" placeholder="Email"  [(ngModel)]="email" />
        </span>
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-lock"></i>
          <input id="password" type="password" pInputText class="w-full md:w-25rem text-color-secondary surface-50 border-200" placeholder="{{ 'LOGIN.PASSWORD' | translate }}"   [(ngModel)]="password"/>
        </span>

        <button pButton pRipple label="{{ 'LOGIN.LOGIN' | translate }}" class="w-full mb-4" (click)="login()"></button>
        <a href="#" class="font-medium text-sm text-300">{{ 'LOGIN.FPASSWORD' | translate }}</a>
        <p class="font-medium text-400 m-0 mt-3">{{ 'LOGIN.ACCOUNT' | translate }}, <a [routerLink]="['/register']" class="text-primary cursor-pointer">{{ 'LOGIN.SIGNUP' | translate }}</a></p>
      </div>

      <div class="mt-5">
        <button
          type="button"
          (click)="toggleDropdown()"
          style="border: none; background: transparent; cursor: pointer;"
          >
          <img [src]="selectedLanguageIcon" alt="Selected Language" style="width: 20px; height: auto;" />
        </button>

        @if (dropdownVisible) {
          <div class="dropdown-menu" style="position: absolute; z-index: 1000;">
            @for (lang of languages; track lang) {
              <div (click)="switchLanguage(lang.value)" style="display: flex; align-items: center; cursor: pointer; padding: 0 0 5px 5px;">
                <img [src]="lang.icon" alt="{{ lang.label }}" style="width: 15px; height: auto; margin-right: 5px;" />
                {{ lang.label }}
              </div>
            }
          </div>
        }
      </div>
    </div>

    <div class="flex flex-wrap align-items-center pb-8 px-4">
      <h4 class="m-0 mr-5" style="line-height: 22px">PHI</h4>
      <!-- <h6 class="m-0 font-medium text-300" style="line-height: 17px">Copyright Ⓒ Covariance P.C.</h6> -->
    </div>
  </div>
</div>
