@if(appointments.length>0){
<div class="grid row-gap-4">
  @for(appointment of appointments ; track appointment){
  <div class="xl:col-3 md:col-6 sm:col-6 col-12">
    <div class="card surface-d">
      <div
        class="card-header flex-column flex-wrap justify-content-around gap-1"
      >
        <div
          class="flex flex-row align-items-center justify-content-between w-full"
        >
          <img
            [src]="appointment.avatarUrl"
            class="border-round-xl"
            style="height: 50px; width: 50px"
          />
          <i
            class="pi pi-ellipsis-v font-bold text-2xl cursor-pointer"
            (click)="showAppointmentDetails(appointment)"
          ></i>
        </div>
        <div class="flex flex-column justify-content-center gap-1">
          <span>{{ appointment.fullName }}</span>
          <span
            ><i class="pi pi-calendar mr-2"></i
            >{{ appointment.scheduledDate | date }}</span
          >
          <span
            ><i class="pi pi-clock mr-2"></i
            >{{ appointment.scheduledTime }}</span
          >
          <div
            class="flex xl:flex-row flex-column xl:justify-content-between gap-2"
          >
            <span class="font-bold text-sm">{{ "Απλή Επίσκεψη" }}</span>
          </div>
        </div>
        <div
          class="flex flex-row flex-wrap align-items-center justify-content-center xl:justify-content-center gap-2 mt-3"
        >
          <p-menu
            #menu
            [popup]="true"
            [model]="menuItems"
            appendTo="body"
          ></p-menu>
          <button
            type="button"
            pButton
            icon="pi pi-chevron-down"
            label="Επεξεργασία"
            (click)="appointmentData = appointment; menu.toggle($event)"
            style="width: auto"
          ></button>

          <button
            pButton
            class="p-button-success"
            (click)="approve(appointment)"
          >
            Αποδοχή
          </button>
        </div>
      </div>
    </div>
  </div>
  }
</div>
@if(appointmentData){
<p-dialog
  [(visible)]="showAppointment"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '75rem' }"
  [modal]="true"
  [closable]="true"
  appendTo="body"
>
  <span class="font-bold text-2xl mb-5">{{ appointmentData.fullName }}</span>
  <div class="mt-3 p-fluid">
    <div class="p-formgrid grid">
      <div class="field lg:col-6 md:col-6 col-12">
        <label class="font-bold" htmlFor="firstname">Όνομα</label>
        <input
          pInputText
          id="firstname"
          type="text"
          disabled
          [value]="appointmentData.fullName.split(' ')[0]"
        />
      </div>
      <div class="field lg:col-6 md:col-6 col-12">
        <label class="font-bold" htmlFor="lastname">Επώνυμο</label>
        <input
          pInputText
          id="lastname"
          type="text"
          disabled
          [value]="appointmentData.fullName.split(' ')[1]"
        />
      </div>
      <div class="field lg:col-6 md:col-6 col-12">
        <label class="font-bold" htmlFor="phone">Τηλέφωνο</label>
        <input
          pInputText
          id="phone"
          type="text"
          disabled
          [value]="appointmentData.mobile"
        />
      </div>
      <div class="field lg:col-6 md:col-6 col-12">
        <label class="font-bold" htmlFor="email">Email</label>
        <input
          pInputText
          id="email"
          type="text"
          disabled
          [value]="appointmentData.email"
        />
      </div>

      <div class="field col-12">
        <label class="font-bold" htmlFor="notes">Σημειώσεις</label>
        <textarea
          pInputTextarea
          id="notes"
          type="text"
          disabled
          [value]="notes"
        ></textarea>
      </div>
    </div>
  </div>
  @if(confirmed_patient_history && confirmed_patient_history.length>0){
  <div class="card">
    <h5>Ιστορικό ραντεβού</h5>
    <p-table
      [value]="getFilteredPatientHistory(appointmentData.fullName)"
      [paginator]="true"
      [rows]="10"
      [sortMode]="'multiple'"
      [tableStyle]="{ 'min-width': '60rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="appointmentDate">
            Ημερομηνία
            <p-sortIcon [field]="'appointmentDate'"></p-sortIcon>
          </th>
          <th pSortableColumn="fullName">
            Όνομα
            <p-sortIcon [field]="'fullName'"></p-sortIcon>
          </th>
          <th pSortableColumn="reasonOfVisit">
            Λόγος Επίσκεψης
            <p-sortIcon [field]="'reasonOfVisit'"></p-sortIcon>
          </th>
          <th pSortableColumn="appointmentStatus.name">
            Κατάσταση
            <p-sortIcon [field]="'appointmentStatus.name'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr>
          <td>{{ appointment.appointmentDate | date : "dd/MM/yyyy" }}</td>
          <td>{{ appointment.fullName }}</td>
          <td>{{ appointment.reasonOfVisit }}</td>
          <td>{{ appointment.appointmentStatus.name }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  }
</p-dialog>
<p-dialog
  header="Προτεινόμενη Ημερομηνία"
  [(visible)]="editAppointmentDialog"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '75rem' }"
  [modal]="true"
  [closable]="true"
  appendTo="body"
  [focusOnShow]="false"
>
  <form #appointmentForm="ngForm" class="p-fluid">
    <div class="p-field mb-4">
      <label for="fullname">Ονοματεπώνυμο Ασθενή:</label>
      <input
        id="fullname"
        type="text"
        pInputText
        name="name"
        [ngModel]="appointmentData.fullName"
        [disabled]="true"
      />
      @if(appointmentForm.submitted){
      <div class="p-error">Παρακαλώ συμπληρώστε Ονοματεπώνυμο.</div>
      }
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλεγμένη Ημερομηνία:</label>
      <input
        id="PreselectedDate"
        type="text"
        pInputText
        name="PreselectedDate"
        [ngModel]="appointmentData.scheduledDate | date : 'dd/MM/yyyy'"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedTime">Επιλεγμένη Ώρα:</label>
      <input
        id="PreselectedTime"
        type="text"
        pInputText
        name="PreselectedTime"
        [ngModel]="appointmentData.scheduledTime"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλέξτε Προτεινόμενη Ημερομηνία:</label>

      <p-calendar
        name="selectedDate"
        id="selectedDate"
        [(ngModel)]="availableDay"
        [inline]="false"
        dateFormat="dd/mm/yy"
        placeholder="30/8/2026"
        [iconDisplay]="'input'"
        [showIcon]="true"
        selectionMode="single"
        [readonlyInput]="true"
        required
        appendTo="body"
        readonly
      >
      </p-calendar>
    </div>
    <div class="p-field mb-4">
      <label for="selectedTime">Επιλέξτε Προτεινόμενη Ώρα:</label>
      <p-multiSelect
        id="selectedTime"
        [options]="availableTimes"
        [(ngModel)]="selectedTime"
        optionLabel="name"
        name="selectedTime"
        placeholder="19:00"
        display="chip"
        [showClear]="true"
        appendTo="body"
      />
    </div>
    <div class="p-field mb-4">
      <label for="notes">Σημειώσεις:</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        [autoResize]="false"
      ></textarea>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button
      pButton
      severity="secondary"
      (click)="editAppointmentDialog = false"
    >
      Ακύρωση
    </button>
    <button
      pButton
      severity="secondary"
      (click)="editAppointmentDateBtn = true; changeAppointmentDate()"
      [disabled]="editAppointmentDateBtn"
    >
      Υποβολή
    </button>
  </ng-template>
</p-dialog>
}
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_SUCCESS }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successDeleteVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">{{ modalMessages.APPOINTMENT_REJECT }}</p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successDeleteVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Σφάλμα"
  [(visible)]="errorVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_ERROR }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="errorVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
}@else{
<div class="w-full h-full text-center text-3xl">Δεν βρέθηκαν ραντεβού</div>
}
