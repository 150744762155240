import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LayoutService } from '../../../public-api';
// import { LayoutService } from 'shared';

@Injectable({
  providedIn: 'root',
})
export class InsurerConfigService {
  userConfigs = [
    {
      insurerName: 'eurolife hellas',
      appName: 'PHI - Eurolife',
      themeColor: 'eurolife',
      logoUrl: '../../assets/layout/images/phi_eurolife.png',
    },
    {
      insurerName: 'Interamerican Hellas',
      appName: 'PHI - Insurance',
      themeColor: 'cov',
      logoUrl: '../../assets/layout/images/phidelity_logo.png',
    },
  ];
  private titleService = inject(Title);
  private layoutService = inject(LayoutService);
  constructor() {}

  getUserConfig(insurerName: string) {
    if (!insurerName) {
      return {
        insurerName: 'Interamerican Hellas',
        appName: 'PHI - Insurance',
        themeColor: 'cov',
        logoUrl: '../../assets/layout/images/phidelity_logo.png',
      };
    }
    let config = this.userConfigs.find(
      (insurer) =>
        insurer.insurerName.toLocaleLowerCase() ==
        insurerName.toLocaleLowerCase()
    );
    return config;
  }

  setInsurerConfig(insurer: any | undefined) {
    const insurance = this.getUserConfig(insurer?.name ?? insurer);
    this.titleService.setTitle(insurance!.appName);
    this.layoutService.logoUrl.set(insurance!.logoUrl);
    this.layoutService.config.update((config) => ({
      ...config,
      theme: insurance!.themeColor,
    }));
  }
}
