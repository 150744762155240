<div
  class="flex flex-column sm:flex-row gap-3 mb-3 justify-content-center align-items-start sm:align-items-center"
>
  <div class="flex align-item-center gap-1">
    <p-badge [value]="" [style]="{ 'background-color': '#ffc107' }" />
    <span class="font-bold">Μη επιβεβαιωμένο</span>
  </div>
  <div class="flex align-item-center gap-1">
    <p-badge [value]="" [style]="{ 'background-color': '#17a2b8' }" />
    <span class="font-bold">Επόμενα Ραντεβού</span>
  </div>

  <div class="flex align-item-center gap-1">
    <p-badge [value]="" [style]="{ 'background-color': '#28a745' }" />
    <span class="font-bold">Ολοκληρωμένο</span>
  </div>
  <div class="flex align-item-center gap-1">
    <p-badge [value]="" [style]="{ 'background-color': '#dc3545' }" />
    <span class="font-bold">Ακυρωμένο</span>
  </div>
</div>
<full-calendar [options]="calendarOptions()"></full-calendar>
<p-dialog
  #dd
  [(visible)]="showDialog"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '36rem' }"
  [modal]="true"
  [closable]="true"
  (onHide)="view = ''"
>
  <ng-template pTemplate="header">
    <span class="text-900 font-semibold text-xl">{{
      view === "display"
        ? changedEvent.title
        : view === "new"
        ? "New Event"
        : "Edit Event"
    }}</span>
  </ng-template>
  <ng-template pTemplate="content">
    @if(view === 'display'){
    <div>
      <!-- <span class="text-900 font-semibold block mb-2">Description</span>
    <span class="block mb-3">{{ changedEvent.description }}</span> -->

      <div class="grid">
        <div class="col-6">
          <div class="text-900 font-semibold mb-2">Start</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-clock text-700 mr-2"></i>
            <!-- <span>{{ changedEvent.start.toISOString().slice(0, 10) }}</span> -->
            <span>{{ changedEvent.start | date : "dd/MM/yyyy hh:mm a" }}</span>
          </p>
        </div>
        <div class="col-6">
          <div class="text-900 font-semibold mb-2">End</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-clock text-700 mr-2"></i>
            <span>{{ changedEvent.end | date : "dd/MM/yyyy hh:mm a" }}</span>
          </p>
        </div>
        <div class="col-12">
          <div class="text-900 font-semibold mb-2">Description</div>
          <p class="flex align-items-center m-0">
            <i class="pi pi-fw pi-book text-700 mr-2"></i>
            <span>{{ changedEvent.description }}</span>
          </p>
        </div>
      </div>
    </div>
    } @if(view !== 'display'){
    <div>
      <div class="grid p-fluid formgrid">
        <div class="col-12 md:col-6 field">
          <label for="title" class="text-900 font-semibold">Title</label>
          <span class="p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <input
              id="title"
              type="text"
              pInputText
              placeholder="Title"
              [(ngModel)]="changedEvent.title"
            />
          </span>
        </div>
        <div class="col-12 md:col-6 field">
          <label for="location" class="text-900 font-semibold">Location</label>
          <span class="p-input-icon-left">
            <i class="pi pi-map-marker"></i>
            <input
              id="location"
              type="text"
              pInputText
              placeholder="Location"
              [(ngModel)]="changedEvent.location"
            />
          </span>
        </div>
        <div class="col-12 field">
          <label for="description" class="text-900 font-semibold"
            >Event Description</label
          >
          <textarea
            id="description"
            type="text"
            pInputTextarea
            [rows]="5"
            [(ngModel)]="changedEvent.description"
            style="resize: none"
          ></textarea>
        </div>
        <div class="col-12 field">
          <label for="company-color" class="text-900 font-semibold"
            >Color</label
          >
          <p-dropdown
            inputId="company-color"
            [appendTo]="dd"
            [options]="tags"
            [(ngModel)]="changedEvent.tag"
            optionLabel="name"
          >
            <ng-template pTemplate="selectedItem">
              @if(changedEvent.tag){
              <div class="flex align-items-center">
                <div
                  class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                  [style.backgroundColor]="changedEvent.tag.color"
                ></div>
                <div>{{ changedEvent.tag.name }}</div>
              </div>
              }
            </ng-template>
            <ng-template let-tag pTemplate="item">
              <div class="flex align-items-center">
                <div
                  class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                  [style.backgroundColor]="tag.color"
                ></div>
                <div>{{ tag.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    }
  </ng-template>
</p-dialog>
