import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { LoadingIndicatorService } from '../../services/shared/loading-indicator.service';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
  private loadingService = inject(LoadingIndicatorService);


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const excludedPatterns = [
      /\/v1\/[^\/]+\/users\/\d+\/conversations/,
      /\/notifications\/\d+/,
    ];

    const excludedStaticUrls = ['/users/chatjwt'];

    if (
      excludedPatterns.some((pattern) => pattern.test(req.url)) ||
      excludedStaticUrls.some((url) => req.url.includes(url))
    ) {
      return next.handle(req);
    }
    this.loadingService.show();

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }
}
