import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorServicesService extends BaseService {
  constructor() {
    super();
  }

  getExamCategories() {
    return this.http.get<any>(
      `${this.apiUrl}doctorServices/categories`,
      this.httpOptions
    );
  }

  getExamSubcategories(categoryId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctorServices/subCategories?categoryId=${categoryId}`,
      this.httpOptions
    );
  }

  getExams(subCategoryId: string) {
    return this.http.get<any>(
      `${this.apiUrl}doctorServices?subCategoryId=${subCategoryId}`,
      this.httpOptions
    );
  }
}
